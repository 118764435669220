import React from 'react';
// import { useModelPreview } from './hooks';

export type ModelPreviewProps = {
    modelUrl: string;
}

export const ModelPreview: React.FC<ModelPreviewProps> = ({modelUrl}) => {
    // const {divRef} = useModelPreview(modelUrl);

    return null;
    // return (
    //     <div id='model' ref={divRef} className='w-100'></div>
    // )
}
