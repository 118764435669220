import React, { useState, useEffect } from 'react';
// import { useMemo } from 'react';
import { Card, Col, Form } from 'react-bootstrap';
import { useSelector } from '../../../../../hooks'
import { LoginSelector } from '../../../../login/store';
import { useListGet } from '../../list/hooks';
import { Resource } from '../../../../../service/api/resource';
import { ObjectApi, Object as AggamesObject } from '../../../../../service/api/object';
import { FormikErrors, FormikValues } from 'formik';
import { ArrowLeftCircle, ArrowRightCircle } from 'react-bootstrap-icons';
import { AuthData } from '../../../../../service/auth';
import { previews } from './previews';
import { GetAllData } from '../../../../../service/api/types';

type ObjectInputProps<T extends FormikValues> = {
    controlId?: string,
    label: string,
    name: keyof T & string,
    values: T,
    errors: FormikErrors<T>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
};

type ObjectInputItem = {
    objectId: number,
    objectName: string,  // this is in fact .name of the main resource, but that gets assigned when creating the object
    resourcePath: string,
};

// logic taken from objects/item.tsx
const typeOfObject = (obj: AggamesObject): 'Model' | 'Image' | 'Video' => {
    if (Object.keys(obj.data).length !== 0) {
        return (Object.keys(obj.data)[0]) as 'Model' | 'Image' | 'Video';
    } else {
        // models are the most common, so they are the default
        // this probably isn't the best approach
        return 'Model';
    }
};

const getMainResource = (obj: AggamesObject, objType: 'Model' | 'Image' | 'Video', auth: AuthData): Resource => {
    if (objType === 'Image' || objType === 'Video') {
        return obj.resources[0];
    } else {
        const mainResourceId = obj.data.Model!.animation;
        const mainResource = obj.resources.find(res => res.id === mainResourceId);
        return mainResource!;
    }
};

export const createObjectInput = <T extends FormikValues>(objectType: 'Model' | 'Image' | 'Video'): React.FC<ObjectInputProps<T>> => {
    const ObjectPreview = previews[objectType];

    const getPage = async (params: { page: number, limit: number }, auth: AuthData): Promise<GetAllData<AggamesObject>> => {
        const allObjects = await ObjectApi.getAll(params, auth);
        allObjects.data = allObjects.data?.filter(obj => typeOfObject(obj) === objectType);

        return allObjects;
    };

    return (props) => {
        const {
            controlId,
            label,
            name,
            errors,
            setFieldValue,
            values,
        } = props;

        const auth = useSelector(LoginSelector.auth);
        const [data, setData] = useState<ObjectInputItem[]>([]);
        const [index, setIndex] = useState(0);

        const {
            data: objects,
            // page: listPage,
            getNextPage,
            // getPrevPage,
        } = useListGet<AggamesObject>({ onGetPage: getPage, limit: 999 });
        

        useEffect(() => {
            (async () => {
                // https://advancedweb.hu/how-to-use-async-functions-with-array-map-in-javascript/
                const pairs: ObjectInputItem[] = await Promise.all(objects.map(async obj => {
                    const objectId = obj.id;

                    const mainResource = getMainResource(obj, objectType, auth);
                    const objectName = mainResource.name;
                    const resourceVrxPath = mainResource.path;
                    const resourcePath = resourceVrxPath.replace(/\.vrx$/, '');

                    return {
                        objectId,
                        objectName,
                        resourcePath,
                    };
                }));


                setData(pairs);
                setFieldValue(name, pairs[0]?.objectId);
            })();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [objects]);

        useEffect(() => {
            !!(values.objectId && data.length) && setDefaultIndex(values.objectId);
             // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [values]);

        const setDefaultIndex = (objectId: number) => {
            const objectIndex = data.findIndex(item => item.objectId === objectId);
            if(objectIndex > -1) {
                setIndex(objectIndex);
            }
            
        }

        // const index = useMemo(() => data.findIndex(item => item.id === values[name]), [data, values, name]);
        

        /*useEffect(() => {
            console.log({
                index,
                currentObject: data[index],
            });
        }, [index]);

        useEffect(() => {
            console.log({objectType, data});
        }, [data]);*/

        const setPrevious = () => {
            const newIndex = index - 1;

            if (newIndex <= -1)
                return;

            setIndex(newIndex);
            setFieldValue(name, data[newIndex].objectId);
        };

        // useEffect(() => {
        //     console.log({listPage});
        // }, [listPage]);

        const setNext = async () => {
            const newIndex = index + 1;
            // console.log({
            //     newIndex,
            //     length: data.length,
            //     data: data.map(d => d.objectId),
            // });

            if (newIndex >= data.length) {
                // console.log('not scrolling');
                return;
            }

            if (newIndex === data.length - 1) {
                // console.log('getting next page of', objectType);
                await getNextPage();
            }


            setIndex(newIndex);
            setFieldValue(name, data[newIndex].objectId);
        };

        return (
            <Form.Group controlId={controlId}>
                <Form.Label className="d-flex align-items-center">
                    {label}

                    <div className="flex-grow-1" />

                    <ArrowLeftCircle className="mr-2 cursor-pointer"
                        onClick={setPrevious}
                    />
                    <ArrowRightCircle className="cursor-pointer"
                        onClick={setNext}
                    />
                </Form.Label>
                <Form.Row>
                    <Col>
                        <div className="w-100 d-flex align-items-stretch overflow-hidden">
                            <div className="w-100 d-flex align-items-stretch position-relative transition-left"
                                style={{left: `${-110 * index}%`}}
                            >
                                {data.map(obj => (
                                    <div key={obj.objectId} className="w-100 flex-grow-0 flex-shrink-0"
                                        style={{marginRight: '10%'}}
                                    >
                                        <Card className="border-dark w-100">
                                            <ObjectPreview resourcePath={obj.resourcePath} />
                                            <Card.Body className="d-flex align-items-center">
                                                <h5 className="flex-grow-1 text-truncate mb-0">
                                                    {obj.objectName}
                                                </h5>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                {errors[name] && (
                    <Form.Text className="invalid-feedback">
                        {errors[name]}
                    </Form.Text>
                )}
            </Form.Group>
        );
    };
};
