import React from 'react';
import {Form} from 'react-bootstrap';
import {FormikErrors, FormikValues} from 'formik';

type GenericTextInputProps<T extends FormikValues> = {
    controlId?: string,
    label: string,
    name: keyof T & string,
    values: T,
    errors: FormikErrors<T>,
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ?
            void :
            (e: string | React.ChangeEvent<any>) => void;
    },
};

export type GenericTextInputArgs = {
    type?: string,
};

export const GenericTextInput: <T extends FormikValues>(args: GenericTextInputArgs) => React.FC<GenericTextInputProps<T>> = ({type}) => {
    return ({controlId, label, name, values, errors, handleChange}) => (
        <Form.Group controlId={controlId}>
            <Form.Label>
                {label}
            </Form.Label>
            <Form.Control type={type}
                          name={name}
                          value={values[name]}
                          onChange={handleChange}
                          isInvalid={!!errors[name]}
            />
            {errors[name] && (
                <Form.Text className="invalid-feedback">
                    {errors[name]}
                </Form.Text>
            )}
        </Form.Group>
    );
};
