import {UserList} from './list';
import {UserEdit} from "./edit";
import {UserCreate} from "./create";

export const UsersRoutes = [
    {
        pathname: '/users',
        exact: true,
        component: UserList,
        title: 'Users',
        menuEntry: true,
    },
    {
        pathname: '/users/create',
        exact: true,
        component: UserCreate,
        title: 'Users',
        menuEntry: false,
    },
    {
        pathname: '/users/edit',
        exact: true,
        component: UserEdit,
        title: 'Users',
        menuEntry: false,
    },
];
