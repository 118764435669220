import { request } from './request';
import { AuthData } from '../auth';
import { PagingParams } from './paging';
import { Game } from './game';
import { GetAllData, UpdateData } from './types';
import { useMemo } from 'react';
import { createContentApi } from './content';

export type Stage = {
    id: number,
    name: string,
    position: number,
};

export type CreateStageData = {
    name: string,
    position: number,
};

export type SetStageData = {
    name: string,
    position: number,
};

export type UpdateStageData = UpdateData<SetStageData>;
export type GetAllStageData = GetAllData<Stage>;

export type GetStageData = {
    id: number,
};

export const createStageApi = (game: Game) => class StageApi {
    static create(data: CreateStageData, auth: AuthData) {
        return request(`/game/${game.id}/stage`)
            .post(data)
            .json()
            .auth(auth)
            .execute();
    }

    static getAll(params: PagingParams, auth: AuthData) {
        return request(`/game/${game.id}/stage`)
            .get()
            .json()
            .params(params)
            .auth(auth)
            .execute() as Promise<GetAllStageData>;
    }

    static update({id, data}: UpdateStageData, auth: AuthData) {
        return request(`/game/${game.id}/stage/${id}`)
            .put(data)
            .json()
            .auth(auth)
            .execute();
    }

    static delete({id}: GetStageData, auth: AuthData) {
        return request(`/game/${game.id}/stage/${id}`)
            .delete()
            .auth(auth)
            .execute();
    }

    static contentApi(stage: Stage) {
        return createContentApi(game, stage);
    }
};

export const useStageApi = (game: Game) => useMemo(() => createStageApi(game), [game]);
