import React from 'react';
import {PencilSquare, Trash, PlusSquare, ArrowRightCircle, ArrowLeftCircle, XCircle} from 'react-bootstrap-icons';

export const Dashboard: React.FC = () => (
    <>
        <h3 className="mt-4">Welcome to the admin dashboard!</h3>
        <p>
            This dashboard is used for adding, editing and deleting markers, games, and users of the Secrets game.
        </p>
        <p>
            After any changes, please restart the application. Also, make sure you are connected to the internet.
        </p>
        <p>
            Click the <b>Markers</b> tab on the top to see all image markers in the game.
            Each marker can be edited by clicking the <PencilSquare /> button and deleted by clicking the <Trash /> button.
            New markers are added via the red <PlusSquare /> button in the upper right corner.
        </p>
        <p>
            You can see all games by clicking the <b>Games</b> tab.
            New game is added via the red <PlusSquare /> button in the upper right corner, the game then appears in the game list in the Secrets app.
            Metadata of a game can be edited after clicking the <PencilSquare /> button in the <b>Actions</b> column.
        </p>
        <p>
            A game consists of stages, a stage consists of contents, a content represents either an image, a video, a model or a question.
            The stages of a game can be edited by clicking the <ArrowRightCircle /> button in the <b>Actions</b> column
                and added via the red <PlusSquare /> button in the upper right corner.
            Click the <ArrowRightCircle /> button to view the contents of the stage.
            New content can be added by the <PlusSquare /> button in the upper right corner,
                you can then select the type of the content – image, video, model, question.
            The new model or the new question will then appear in the game in the app.
        </p>
        <p>
            While creating new content, use <ArrowLeftCircle /><ArrowRightCircle /> buttons to select a marker. If creating content of type
            question, please mark atleast one answer as correct by clicking the <XCircle/> button on the left.
        </p>
        <p>
            The supported format for 3D models is <a href="https://en.wikipedia.org/wiki/FBX">FBX</a>,
                which can be exported from a software such as Autodesk 3ds Max, Autodesk Maya or Blender.
            The supported formats for textures are PNG, JPG and TIF.
        </p>
    </>
);
