import React from 'react';
import {ListItemProps} from '../ui/list';
import {User} from '../../../service/api/user';
import {PencilSquare, Trash} from 'react-bootstrap-icons';

export const UserItem: React.FC<ListItemProps<User>> = ({item, onDelete, onEdit}) => {

    return (
        <tr>
            <td>
                {item.id}
            </td>
            <td>
                {item.username}
            </td>
            <td>
                {item.name || '-'}
            </td>
            <td>
                {item.age || '-'}
            </td>
            <td>
                {item.blocked ? 'Yes' : 'No'}
            </td>
            <td>
                <PencilSquare
                    className="mr-2 cursor-pointer"
                    onClick={onEdit}
                />
                <Trash className="cursor-pointer"
                       onClick={onDelete}
                />
            </td>
        </tr>
    );
};
