import React from 'react';
import { GameForm } from './form';
import { useLocation } from 'react-router-dom';
import { Game } from '../../../service/api/game';

export const GameEdit: React.FC = () => {
    const location = useLocation<{ initialValues: Game }>();
    const state = location.state?.initialValues;

    return (
        <GameForm id={state.id}
                  initialValues={{
                      name: state.name,
                      subtitle: state.subtitle,
                      description: state.description,
                      difficulty: state.difficulty,
                      location: state.location,
                  }}
        />
    );
};
