/**
 * Class representing an API request
 */
import {AuthData} from '../auth';

type RequestExecutor = () => Promise<Response> | Promise<any>;

export class Request {
    static ApiEndpoint = process.env.REACT_APP_API_ENDPOINT?.replace(/\/$/, '') || '';
    static DefaultInit: RequestInit = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'no-cache',
    };

    _path: string;
    _init: RequestInit;
    _params: Record<string, string>;
    _executor: RequestExecutor;

    /**
     * Constructs a new Request object
     */
    constructor(path = '', init = Request.DefaultInit) {
        this._path = path;
        this._init = init;
        this._params = {};
        this._executor = () => this._defaultExecutor();
    }

    /**
     * Returns full requests path
     */
    get path(): string {
        return `${Request.ApiEndpoint}${this._path}`;
    }

    /**
     * Returns requests initialization object
     */
    get init(): RequestInit {
        return this._init;
    }

    /**
     * Executes the request and returns a promise
     */
    execute(): ReturnType<RequestExecutor> {
        return this._executor();
    }


    /**
     * Sets the request method to GET
     */
    get(): Request {
        this._init.method = 'GET';
        this._init.body = null;
        return this;
    }

    /**
     * Sets the request method to POST and optionally sets the request body
     */
    post(body: any = null): Request {
        this._init.method = 'POST';
        this._init.headers = {'Accept': 'application/json', 'Content-Type': 'application/json'};
        this._init.body = JSON.stringify(body);
        return this;
    }

    /**
     * Sets the request method to POST
     */
    postFormData(body: FormData): Request {
        this._init.method = 'POST';
        this._init.headers = {'Accept': 'application/json'};
        this._init.body = body;
        return this;
    }

    /**
     *  Sets the request method to PUT and optionally sets the request body
     */
    put(body: any = null): Request {
        this._init.method = 'PUT';
        this._init.body = JSON.stringify(body);
        return this;
    }

    /**
     * Sets the request method to POST
     */
    putFormData(body: FormData): Request {
        this._init.method = 'PUT';
        this._init.headers = {'Accept': 'application/json'};
        this._init.body = body;
        return this;
    }

    /**
     *  Sets the request method to DELETE
     */
    delete(): Request {
        this._init.method = 'DELETE';
        this._init.body = null;
        return this;
    }

    /**
     * Adds headers to the request, overwriting already existing ones
     */
    headers(headers: HeadersInit): Request {
        this._init.headers = {...this._init.headers, ...headers};
        return this;
    }

    /**
     * Adds authorizations headers based on the auth param
     */
    auth(auth: AuthData): Request {
        return this.headers({Authorization: 'Bearer ' + auth.accessToken});
    }

    params(params: Record<string, any>): Request {
        Object.assign(this._params, params);
        return this;
    }

    /**
     * Adds request result parsing from JSON
     */
    json(): Request {
        this._executor = () => this._jsonExecutor();
        return this;
    }


    /**
     * Default request executor
     */
    _defaultExecutor(): Promise<Response> {
        const params = new URLSearchParams(this._params).toString();
        return fetch(`${this.path}${params.length ? `?${params}` : ''}`, this.init);
    }

    /**
     * Json request executor
     */
    _jsonExecutor(): Promise<any> {
        return this._defaultExecutor()
            .then(response => response.json());
    }
}

/**
 * Constructs a new API request
 */
export const request = (path: string): Request => new Request(path);
