import React from 'react';
import {createList} from '../ui/list';
import {Resource, ResourceApi} from '../../../service/api/resource';
import {MarkerItem} from './item';

const List = createList<Resource>();

export const MarkerList: React.FC = () => (
    <List title="Markers"
          itemName="Marker"
          createUrl="/markers/create"
          editUrl="/markers/edit"
          onGetPage={async (params, auth) =>
              await ResourceApi.getAllByGroup('Marker', params, auth)
          }
          onConfirmDelete={ResourceApi.delete}
          listItem={MarkerItem}
    />
);
