import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { CloseButton, Form, Image } from 'react-bootstrap';
import { FormikErrors, FormikValues } from 'formik';
import { ModelPreview } from '../model-preview';

type FileUploadProps<T> = {
    controlId?: string,
    label: string,
    name: keyof T & string,
    values: T,
    multiple?: boolean,
    errors: FormikErrors<T>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
};

export const createFileUpload: <T extends FormikValues>(type: 'image' | 'video' | 'model') => React.FC<FileUploadProps<T>> = (type) => {
    return ({controlId, label, name, values, multiple, errors, setFieldValue}) => {
        const files: File[] = values[name];

        // the file to be previewed (now just files[0])
        // encoded in base64
        const [fileSrc, setFileSrc] = useState('');

        useEffect(() => {
            if (files) {
                const reader = new FileReader();

                reader.onloadend = event => {
                    //console.log(event.target?.result);
                    return setFileSrc(event.target?.result as string || '');
                };

                reader.readAsDataURL(files[0]);

                return () => {
                    reader.onloadend = null;
                };
            } else {
                setFileSrc('');
            }
        }, [files]);

        /**
         * The filenames to be shown after uploading.
         * This can't be a comma separated string, as only one line is allowed.
         */
        const filenames: string = useMemo(() => {
            if (!files || files.length === 0)
                return '';
            else if (files.length === 1)
                return files[0].name;
            else
                return `${files.length} files`;
        }, [files]);

        const clearFile = useCallback(() => setFieldValue(name, null), [setFieldValue, name]);

        const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
            const newFiles = event.currentTarget.files;
            setFieldValue(name, newFiles);
        }, [setFieldValue, name]);

        const renderObject = (objectType: typeof type) => {
            //console.log('renderObject', objectType);
            switch (objectType) {
                case 'image':
                    return (
                        <>
                            <CloseButton className="mt-1"
                                         onClick={clearFile}
                            />
                            <Image className="w-100 mb-3 rounded"
                                   src={fileSrc}
                            />
                        </>
                    )
                case 'video':
                    return (
                        <>
                            <CloseButton className="mt-1"
                                         onClick={clearFile}
                            />
                            <video className="w-100 mb-3 rounded"
                                   src={fileSrc}
                                   controls
                            />
                        </>
                    )
                case 'model':
                    return (
                        <>
                            <CloseButton className="mt-1"
                                         onClick={clearFile}
                            />
                            <ModelPreview modelUrl={fileSrc}/>
                        </>
                    )
            }
        }

        return (
            <Form.Group controlId={controlId}>
                <Form.Label>
                    {label}
                </Form.Label>

                {fileSrc && renderObject(type)}

                <Form.File custom>
                    <Form.File.Input accept={type === 'model' ? '.fbx,.png,.jpeg,.tif' : `${type}/*`}
                                     name={name}
                                     multiple={!!multiple}
                                     onChange={onChange}
                                     isInvalid={!!errors[name]}
                    />
                    <Form.File.Label>
                        {filenames}
                    </Form.File.Label>

                    {errors[name] && (
                        <Form.Text className="invalid-feedback">
                            {errors[name]}
                        </Form.Text>
                    )}
                </Form.File>
            </Form.Group>
        );
    };
};
