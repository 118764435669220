import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { createTextInput } from '../../ui/input/text';
import { FormikHelpers } from 'formik/dist/types';
import { useSelector } from '../../../hooks';
import { LoginSelector } from '../../login/store';
import { useHistory } from 'react-router-dom';
import { UserInfoData, UserApi, CreateUserData } from '../../../service/api/user';
import { createSelectInput } from '../../ui/input/select';
import { createFileUpload } from '../../ui/input/file-upload';
import { createPasswordInput } from '../../ui/input/password';
import { FormContainer } from '../ui/form/container';


type UserEditFormProps = {
    id: number,
    initialValues: UserInfoData,
};

type UserCreateFormProps = {
    initialValues: CreateUserData,
};

const PasswordInput = createPasswordInput<CreateUserData>();
const TextInputEdit = createTextInput<UserInfoData>();
const TextInputCreate = createTextInput<CreateUserData>();
const ImageUpload = createFileUpload<UserInfoData>('image');
const SelectBlockedInput = createSelectInput<UserInfoData>(['No', 'Yes'].map(
    (option, id) => ({
        value: id,
        text: option,
    })));

export const UserEditForm: React.FC<UserEditFormProps> = ({initialValues, id}) => {
    const auth = useSelector(LoginSelector.auth);
    const history = useHistory();

    const onSubmit = async (values: UserInfoData, {setSubmitting, setFieldError}: FormikHelpers<UserInfoData>) => {
        const result = await UserApi.update({id, data: values as UserInfoData}, auth);

        if (result.error) {
            if (result.info)
                result.info.forEach((error: any) => setFieldError(error.context.label, error.message));
            else if (result.message)
                alert(result.message);
        } else
            history.goBack();
    };

    return (
        <FormContainer title="Users">
            <Formik initialValues={initialValues}
                    onSubmit={onSubmit}
            >
                {({errors, isSubmitting, values, handleChange, setFieldValue}) => (
                    <FormikForm className="py-3">
                        <Row className="justify-content-center">
                            <Col md={10} lg={6} xl={5}>
                                <TextInputEdit controlId="username"
                                               label="Username"
                                               name="username"
                                               values={values}
                                               errors={errors}
                                               handleChange={handleChange}
                                />
                                <TextInputEdit controlId="name"
                                               label="Name"
                                               name="name"
                                               values={values}
                                               errors={errors}
                                               handleChange={handleChange}
                                />
                                <ImageUpload controlId="picture"
                                             label="Profile picture"
                                             name="picture"
                                             values={values}
                                             errors={errors}
                                             setFieldValue={setFieldValue}
                                />
                            </Col>
                            <Col md={10} lg={6} xl={5}>
                                <TextInputEdit controlId="age"
                                               label="Age"
                                               name="age"
                                               values={values}
                                               errors={errors}
                                               handleChange={handleChange}
                                />
                                <SelectBlockedInput controlId="blocked"
                                                    label="Blocked"
                                                    name="blocked"
                                                    values={values}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3 mt-lg-0">
                            <Col md={1} lg={8} xl={8}/>
                            <Col md={10} lg={4} xl={3}
                                 className="d-flex justify-content-end"
                            >
                                <Button
                                    className="w-50 mr-3"
                                    variant="outline-secondary"
                                    type="reset"
                                    disabled={isSubmitting}
                                >
                                    Clear
                                </Button>
                                <Button
                                    className="w-50"
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </FormikForm>
                )}
            </Formik>
        </FormContainer>
    );
};


export const UserCreateForm: React.FC<UserCreateFormProps> = ({initialValues}) => {
    const auth = useSelector(LoginSelector.auth);
    const history = useHistory();

    const onSubmit = async (values: CreateUserData, {setSubmitting, setFieldError}: FormikHelpers<CreateUserData>) => {
        const result = await UserApi.create(values, auth);

        if (result.error) {
            if (result.info)
                result.info.forEach((error: any) => setFieldError(error.context.label, error.message));
            else if (result.message)
                alert(result.message);
        } else
            history.goBack();
    };

    return (
        <FormContainer title="Users">
            <Formik initialValues={initialValues}
                    onSubmit={onSubmit}
            >
                {({errors, isSubmitting, values, handleChange, setFieldValue}) => (
                    <FormikForm className="py-3">
                        <Row className="justify-content-center">
                            <Col md={10} lg={6} xl={5}>
                                <TextInputCreate
                                    controlId="username"
                                    label="Username"
                                    name="username"
                                    values={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                />
                                <TextInputCreate
                                    controlId="email"
                                    label="Email"
                                    name="email"
                                    values={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                />
                                <PasswordInput
                                    controlId="password"
                                    label="Password"
                                    name="password"
                                    values={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                />
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={10} lg={6} xl={3}
                                 className="d-flex justify-content-end"
                            >
                                <Button
                                    className="w-50 mr-3"
                                    variant="outline-secondary"
                                    type="reset"
                                    disabled={isSubmitting}
                                >
                                    Clear
                                </Button>
                                <Button
                                    className="w-50"
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </FormikForm>
                )}
            </Formik>
        </FormContainer>
    );
};
