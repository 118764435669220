import React, { useCallback, useEffect, useState } from 'react';
import { UserEditForm } from './form';
import { useSelector } from '../../../hooks';
import { LoginSelector } from '../../login/store';
import { useLocation } from 'react-router-dom';
import { User, UserApi, UserInfoData } from '../../../service/api/user';
import { renderRedirect } from '../../../service/router';
import { Loading } from '../ui/loading';

export const UserEdit: React.FC = () => {
    const auth = useSelector(LoginSelector.auth);
    const location = useLocation<{ initialValues: User }>();
    const state = location.state?.initialValues;
    const [isLoading, setIsLoading] = useState(true);
    const [file, setFile] = useState<File | null>(null);

    const loadImage = useCallback(async () => {
        if (state.picture) {
            const result = await UserApi.getProfilePicture({id: state.id}, auth);
            const data = new File([await result.blob()], state.picture);
            setFile(data);
        }
        setIsLoading(false);
    }, [auth, state]);

    useEffect(() => {
        if (state && !file) {
            loadImage();
        }
    }, [state, file, loadImage]);

    if (!state)
        return renderRedirect('/404');

    const initialValues: UserInfoData = {
        picture: file,
        username: state.username,
        name: state.name ?? '',
        age: state.age,
        blocked: state.blocked ? 1 : 0,
    };

    if (isLoading)
        return <Loading/>;

    return (
        <UserEditForm id={state.id}
                      initialValues={initialValues}
        />
    );
};
