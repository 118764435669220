import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { HomeRoutes } from './routes';
import { useDispatch } from '../../hooks';
import { LoginAction } from '../login/store';
import logoImage from 'assets/images/logo_180x180.png';

export const HomeNavbar: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const logout = () => dispatch(LoginAction.logout());

    return (
        <Navbar collapseOnSelect expand="lg" sticky="top" className="bg-white border-bottom">
            <Container className="px-sm-3">
                <Navbar.Brand href="/home" className="py-2">
                    <img src={logoImage}
                         className="d-inline-block align-top mr-3"
                         height={30}
                         alt="logo"
                    />
                    AGGames
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {HomeRoutes
                            .filter(route => route.menuEntry)
                            .map(route => (
                                    <Nav.Item key={route.pathname}>
                                        <Nav.Link href={route.pathname}
                                                  active={location.pathname.indexOf(route.pathname) === 0}
                                        >
                                            {route.title || route.pathname}
                                        </Nav.Link>
                                    </Nav.Item>
                                ),
                            )}
                    </Nav>
                    <Nav>
                        <Nav.Item>
                            <div className="nav-link active cursor-pointer" onClick={logout}>
                                Logout
                            </div>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};
