import React from 'react';
import { Indexable } from './hooks';
import { createGenericList, GenericListContainer, GenericListContainerProps, ListProps } from './generic';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';

// eslint-disable-next-line
type TableListContainerProps<T extends Indexable> = {
    columns: string[],
};

type TableListContainer<T extends Indexable> = React.FC<GenericListContainerProps<T> & TableListContainerProps<T>>;

const createTableListContainer = <T extends Indexable>(): TableListContainer<T> => {
    return props => {
        const {
            // count,
            pages,

            columns,
            page,
            data,
            // isLoading,

            getPrevPage,
            getNextPage,

            onEdit,
            onDelete,
        } = props;

        const ListItem = props.listItem;

        const route = useRouteMatch();

        return (
            <>
                <Row className="my-3">
                    <Col>
                        <div className="border border-dark rounded p-1 m-0 overflow-auto">
                            <Table className="w-100" borderless>
                                <thead>
                                <tr>
                                    {columns.map(column => (
                                        <th key={column}>
                                            {column}
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((item: T, index: number) => (
                                    <ListItem key={`list-item-${index}-${item.id}`}
                                              item={item}
                                              onEdit={onEdit(item.id)}
                                              onDelete={onDelete(item.id)}
                                    />
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>

                {page !== -1 && route.path !== '/stages' && route.path !== '/contents' && (
                    <Row className="pb-3">
                        <Col sm={0} md={0}
                             className="d-lg-none"
                        />
                        <Col sm={12} md={12} lg={'auto'} className={`${pages <= 1 ? "d-none" : ""}`}>
                            <nav aria-label="pagination">
                                <ul className="pagination">
                                    <li className={`page-item ${page <= 1 ? "disabled" : "" }`}>
                                        <Button className="page-link" onClick={getPrevPage}>Previous</Button>
                                    </li>
                                    <li className={`page-item ${page >= pages ? "disabled" : "" }`}>
                                        <Button className="page-link" onClick={getNextPage}>Next</Button>
                                    </li>
                                </ul>
                            </nav>
                        </Col>
                    </Row>
                )}
            </>
        );
    };
};

export const createTableList = <T extends Indexable>(): React.FC<ListProps<T> & TableListContainerProps<T>> =>
    createGenericList<T>(
        createTableListContainer<T>() as GenericListContainer<T>,
    );
