import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';

import {App} from './app';
import {AppPersistor, AppStore} from './store';
import {PersistGate} from 'redux-persist/integration/react';

const render = () => {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={AppStore}>
                <PersistGate persistor={AppPersistor} loading={null}>
                    <Router>
                        <App/>
                    </Router>
                </PersistGate>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root'),
    );
};

render();
