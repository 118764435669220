import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import { Resource, ResourceApi } from '../../../service/api/resource';
import { useSelector } from '../../../hooks';
import { LoginSelector } from '../../login/store';
import { GetListItemProps, ListItemProps } from '../ui/list/generic';

export const RawMarkerItem: React.FC<GetListItemProps<Resource>> = ({item, onEdit, onDelete}) => {
    const {id, name} = item;
    const auth = useSelector(LoginSelector.auth);
    const [src, setSrc] = useState('');

    const loadImage = useCallback(async () => {
        if (src) {
            URL.revokeObjectURL(src);
            setSrc('');
        }

        if (id) {
            const result = await ResourceApi.getResourceById({id}, auth);
            setSrc(URL.createObjectURL(await result.blob()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, auth]);

    useEffect(() => {
        if (id)
            loadImage();
    }, [id, loadImage]);

    return (
        <Card className="border-dark w-100">
            <Card.Img variant="top" src={src}/>
            <Card.Body className="d-flex align-items-center">
                <h5 className="flex-grow-1 text-truncate mb-0">
                    {name}
                </h5>
                <div className="d-flex align-items-center">
                    {onEdit && (
                        <PencilSquare
                            className="mr-2 cursor-pointer"
                            onClick={onEdit}
                        />
                    )}
                    {onDelete && (
                        <Trash className="cursor-pointer"
                               onClick={onDelete}
                        />
                    )}
                </div>
            </Card.Body>
        </Card>
    );
};

export const MarkerItem: React.FC<ListItemProps<Resource>> = (props) => {
    return (
        <Col md={6} lg={4} xl={3} className="py-3">
            <RawMarkerItem {...props}/>
        </Col>
    );
};
