import { StageList } from './list';
import { StageCreate } from './create';
import { StageEdit } from './edit';

export const StagesRoutes = [
    {
        pathname: '/stages',
        exact: true,
        component: StageList,
        title: 'Stages',
        menuEntry: false,
    },
    {
        pathname: '/stages/create',
        exact: true,
        component: StageCreate,
        title: 'Stages',
        menuEntry: false,
    },
    {
        pathname: '/stages/edit',
        exact: true,
        component: StageEdit,
        title: 'Stages',
        menuEntry: false,
    },
];
