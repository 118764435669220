import { AuthApi } from '../../service/api/auth';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from '../../hooks';
import { LoginAction, LoginSelector } from './store';
import { FormikHelpers } from 'formik/dist/types';
import { useEffect } from 'react';

export type FormValues = {
    email: string;
    password: string;
};

export const useLogin = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const auth = useSelector(LoginSelector.auth);

    useEffect(() => {
        if (auth)
            history.replace('/home');
    }, [auth, history]);

    const onSubmit = async (values: FormValues, {setErrors, setStatus}: FormikHelpers<FormValues>) => {
        setStatus(null);

        const result = await AuthApi.login(values);

        if (result.error) {
            if (result.info instanceof Array) {
                setErrors(Object.fromEntries(
                    result.info.map((error: any) => [error.context.key, error.message]),
                ));
            } else {
                setErrors({});
                setStatus(result.message);
            }
        } else {
            dispatch(LoginAction.loginSuccess(result));
        }
    };

    return {
        onSubmit,
    };
};
