import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthData } from '../../service/auth';
import { AuthApi } from '../../service/api/auth';
import { AppState } from '../../store';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { getErrors } from '../../service/errors/state';

export type State = {
    auth: AuthData | null,
};

const initialState: State = {
    auth: null,
};

export const refreshLogin = createAsyncThunk(
    'login/refreshLogin',
    async (_, {getState}) => {
        const state = getState() as any;

        const auth: State['auth'] = state.login.auth;

        if (auth) {
            const result = await AuthApi.refresh(auth);
            const errors = getErrors(result);
            if (errors) {
                return null;
            }
            return result.refreshToken && result.accessToken ? result : auth;
        } else
            return null;
    },
);

const slice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<AuthData>) => {
            state.auth = action.payload;
        },
        logout: (state) => {
            state.auth = null;
        },
    },
    extraReducers: builder =>
        builder.addCase(refreshLogin.fulfilled, (state, action) => {
            state.auth = action.payload;
        }),
});

export const loginReducer = persistReducer({
    key: 'ag-games-login',
    storage,
    transforms: [
        encryptTransform({
            secretKey: process.env.REACT_APP_STORAGE_KEY!,
            onError: console.error,
        }),
    ],
}, slice.reducer);

export const LoginAction = slice.actions;

export const LoginSelector = {
    auth: (state: AppState) => state.login.auth,
};
