import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { createTextInput } from '../../ui/input/text';
import { FormikHelpers } from 'formik/dist/types';
import { useSelector } from '../../../hooks';
import { LoginSelector } from '../../login/store';
import { useHistory } from 'react-router-dom';
import { Game } from '../../../service/api/game';
import { SetStageData, useStageApi } from '../../../service/api/stage';
import { FormContainer } from '../ui/form/container';

type StageFormProps = {
    id?: number,
    initialValues: SetStageData,
    game: Game,
};

const TextInput = createTextInput<SetStageData>();

export const StageForm: React.FC<StageFormProps> = ({initialValues, id, game}) => {
    const auth = useSelector(LoginSelector.auth);
    const history = useHistory();

    const StageApi = useStageApi(game);

    const onSubmit = async (values: SetStageData, {
        setSubmitting,
        setStatus,
        setFieldError,
    }: FormikHelpers<SetStageData>) => {
        setSubmitting(true);
        const apiCall = id ?
            () => StageApi.update({id, data: values}, auth) :
            () => StageApi.create(values, auth);

        const result = await apiCall();

        setSubmitting(false);
        if (result.error) {
            if (result.info) {
                if (Array.isArray(result.info)) {
                    result.info.forEach((error: any) => setFieldError(error.context.label, error.message));
                } else {
                    for (const error in result.info) {
                        setFieldError(result.info[error].key, result.message);
                    }
                }
            } else if (result.message) {
                alert(result.message);
            }
            setStatus(result.message);
        } else
            history.goBack();
    };

    return (
        <FormContainer title="Stages">
            <Formik initialValues={initialValues}
                    onSubmit={onSubmit}
            >
                {({errors, isSubmitting, values, handleChange, setFieldValue}) => (
                    <FormikForm className="py-3">
                        <Row className="justify-content-center">
                            <Col md={10} lg={6} xl={5}>
                                <TextInput controlId="name"
                                           label="Name"
                                           name="name"
                                           values={values}
                                           errors={errors}
                                           handleChange={handleChange}
                                />
                            </Col>
                            <Col md={10} lg={6} xl={5}>
                                <TextInput controlId="position"
                                           label="Position"
                                           name="position"
                                           values={values}
                                           errors={errors}
                                           handleChange={handleChange}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3 mt-lg-0">
                            <Col md={1} lg={8} xl={8}/>
                            <Col md={10} lg={4} xl={3}
                                 className="d-flex justify-content-end"
                            >
                                <Button
                                    className="w-50 mr-3"
                                    variant="outline-secondary"
                                    type="reset"
                                    disabled={isSubmitting}
                                >
                                    Clear
                                </Button>
                                <Button
                                    className="w-50"
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </FormikForm>
                )}
            </Formik>
        </FormContainer>
    );
};
