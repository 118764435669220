import React from 'react';
import { Form } from 'react-bootstrap';
import { FormikErrors, FormikValues } from 'formik';

type TextAreaInputProps<T extends FormikValues> = {
    controlId?: string,
    label: string,
    name: keyof T & string | string,
    values: T,
    errors: FormikErrors<T>,
    rows?: number,
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ?
            void :
            (e: string | React.ChangeEvent<any>) => void;
    },
};

export const createTextAreaInput: <T extends FormikValues>() => React.FC<TextAreaInputProps<T>> = () => {
    return ({controlId, label, name, values, errors, rows, handleChange}) => {

        const getValue = (name: string): string => {
            let value = values;
            for (const key of name.split('.')) {
                value = value[key];
            }
            return value as any as string;
        }

        return (
            <Form.Group controlId={controlId}>
                <Form.Label>
                    {label}
                </Form.Label>
                <Form.Control as="textarea"
                              style={{
                                  resize: 'none',
                              }}
                              name={name}
                              value={getValue(name)}
                              onChange={handleChange}
                              isInvalid={!!errors[name]}
                              rows={rows}
                />
                {errors[name] && (
                    <Form.Text className="invalid-feedback">
                        {errors[name]}
                    </Form.Text>
                )}
            </Form.Group>
        )
    };
};
