import React from 'react';
import { createTableList } from '../ui/list';
import { ContentItem } from './item';
import { useLocation } from 'react-router-dom';
import { Game } from '../../../service/api/game';
import { Stage, useStageApi } from '../../../service/api/stage';
import { Content } from '../../../service/api/content';

const List = createTableList<Content>();

export const ContentsList: React.FC = () => {
    const location = useLocation<{ game: Game, stage: Stage }>();

    const ContentApi = useStageApi(location.state.game)
        .contentApi(location.state.stage);

    return (
        <List title="Contents"
              itemName="Content"
              columns={[
                  'ID - Title',
                  'Order',
                  'Type',
                  'Actions',
              ]}
              createUrl="/contents/create"
              editUrl="/contents/edit"
              onGetPage={async (params, auth) =>
                  await ContentApi.getAll(params, auth)
              }
              onConfirmDelete={ContentApi.delete}
              listItem={ContentItem}
        />
    );
};
