import React from 'react';
import {ContentForm} from './form';
import {ContentType, ObjectPlacementType} from '../../../service/api/content';

export const ContentCreate: React.FC = () => {
    return (
        <ContentForm initialValues={{
            type: ContentType.Image,
            title: "",
            hintText: "",
            recordingKey: "",
            order: 0,
            completeButtonLabel: "",
            autoContinueTimeout: null,
            objectPlacement: ObjectPlacementType.AIR,
            transforms: {
                translation: [0, 0, 0],
                rotation: [0, 0, 0],
                scale: [0.2, 0.2, 0.2],
                main: {
                    translation: [0, 0, 0],
                    rotation: [0, 0, 0],
                    scale: [0.2, 0.2, 0.2],
                },
                alt: {
                    translation: [0, 0, 0],
                    rotation: [0, 0, 0],
                    scale: [0.2, 0.2, 0.2],
                },
            },
            imageMarkerId: null,

            question: {
                question: '',
                answers: [],
            },
            image: null,
            video: null,
        }}/>
    );
};
