import React from 'react';
import { ListItemProps } from '../ui/list';
import { Stage } from '../../../service/api/stage';
import { ArrowRightCircle, PencilSquare, Trash } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';

export const StageItem: React.FC<ListItemProps<Stage>> = ({item, onDelete, onEdit}) => {
    const history = useHistory<{}>();

    return (
        <tr>
            <td>
                {item.id}
            </td>
            <td>
                {item.name}
            </td>
            <td>
                {item.position}
            </td>
            <td>
                <ArrowRightCircle className="mr-2 cursor-pointer"
                                  onClick={() => history.push(
                                      '/contents',
                                      {
                                          ...history.location.state,
                                          stage: item,
                                      },
                                  )}
                />
                <PencilSquare
                    className="mr-2 cursor-pointer"
                    onClick={onEdit}
                />
                <Trash className="cursor-pointer"
                       onClick={onDelete}
                />
            </td>
        </tr>
    );
};
