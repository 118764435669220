import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { createTextInput } from '../../ui/input/text';
import { FormikHelpers } from 'formik/dist/types';
import { useSelector } from '../../../hooks';
import { LoginSelector } from '../../login/store';
import { useHistory } from 'react-router-dom';
import { CreateGameData, DifficultyLabel, GameApi } from '../../../service/api/game';
import { createTextAreaInput } from '../../ui/input/text-area';
import { createSelectEnumInput } from '../../ui/input/select';
import { createCoordsInput } from '../../ui/input/coords';
import { FormContainer } from '../ui/form/container';

type GameFormProps = {
    id?: number,
    initialValues: CreateGameData,
};

const TextInput = createTextInput<CreateGameData>();
const TextAreaInput = createTextAreaInput<CreateGameData>();
const SelectDifficultyInput = createSelectEnumInput<CreateGameData>(Object.values(DifficultyLabel));
const CoordsInput = createCoordsInput<CreateGameData>();

export const GameForm: React.FC<GameFormProps> = ({initialValues, id}) => {
    const auth = useSelector(LoginSelector.auth);
    const history = useHistory();

    const onSubmit = async (values: CreateGameData, {setSubmitting, setFieldError}: FormikHelpers<CreateGameData>) => {
        const apiCall = id ?
            () => GameApi.update({id, data: values}, auth) :
            () => GameApi.create(values, auth);

        const result = await apiCall();

        if (result.error) {
            if (result.info) {
                if (Array.isArray(result.info)) {
                    result.info.forEach((error: any) => setFieldError(error.context.label, error.message));
                } else {
                    for (const error in result.info) {
                        setFieldError(result.info[error].key, result.message);
                    }
                }
            } else if (result.message) {
                alert(result.message);
            }
        } else
            history.goBack();
    };

    return (
        <FormContainer title="Games">
            <Formik initialValues={initialValues}
                    onSubmit={onSubmit}
            >
                {({errors, isSubmitting, values, handleChange, setFieldValue}) => (
                    <FormikForm className="py-3">
                        <Row className="justify-content-center">
                            <Col md={10} lg={6} xl={5}>
                                <TextInput controlId="name"
                                           label="Name"
                                           name="name"
                                           values={values}
                                           errors={errors}
                                           handleChange={handleChange}
                                />
                                <TextInput controlId="subtitle"
                                           label="Subtitle"
                                           name="subtitle"
                                           values={values}
                                           errors={errors}
                                           handleChange={handleChange}
                                />
                                <TextAreaInput controlId="description"
                                               label="Description"
                                               name="description"
                                               values={values}
                                               errors={errors}
                                               handleChange={handleChange}
                                               rows={4}
                                />
                            </Col>
                            <Col md={10} lg={6} xl={5}>
                                <SelectDifficultyInput controlId="difficulty"
                                                       label="Difficulty"
                                                       name="difficulty"
                                                       values={values}
                                                       errors={errors}
                                                       handleChange={handleChange}
                                />
                                <CoordsInput controlId="location"
                                             label="Location"
                                             name="location"
                                             values={values}
                                             errors={errors}
                                             setFieldValue={setFieldValue}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3 mt-lg-0">
                            <Col md={1} lg={8} xl={8}/>
                            <Col md={10} lg={4} xl={3}
                                 className="d-flex justify-content-end"
                            >
                                <Button
                                    className="w-50 mr-3"
                                    variant="outline-secondary"
                                    onClick={() => history.goBack()}
                                    disabled={isSubmitting}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="w-50"
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </FormikForm>
                )}
            </Formik>
        </FormContainer>
    );
};
