import React from 'react';
import {Spinner} from 'react-bootstrap';

export const Loading: React.FC = () => (
    <div className="col position-absolute d-flex justify-content-center m-5"
         style={{left: 0, top: 0, right: 0}}
    >
        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
    </div>
);
