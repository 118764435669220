import {Dashboard} from './dashboard';
import {MarkersRoutes} from './markers/routes';
import {GamesRoutes} from './games/routes';
import {StagesRoutes} from './stages/routes';
import {ContentsRoutes} from './contents/routes';
import {UsersRoutes} from "./users/routes";
import {ObjectsRoutes} from "./objects/routes";

export const HomeRoutes = [
    {
        pathname: '/home',
        exact: true,
        component: Dashboard,
        title: 'Home',
        menuEntry: false,
    },
    ...MarkersRoutes,
    ...GamesRoutes,
    ...StagesRoutes,
    ...UsersRoutes,
    ...ContentsRoutes,
    ...ObjectsRoutes,
];
