import {MarkerList} from './list';
import {MarkerCreate} from './create';
import {MarkerEdit} from './edit';

export const MarkersRoutes = [
    {
        pathname: '/markers',
        exact: true,
        component: MarkerList,
        title: 'Markers',
        menuEntry: true,
    },
    {
        pathname: '/markers/create',
        exact: true,
        component: MarkerCreate,
        title: 'Markers',
        menuEntry: false,
    },
    {
        pathname: '/markers/edit',
        exact: true,
        component: MarkerEdit,
        title: 'Markers',
        menuEntry: false,
    },
];
