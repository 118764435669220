import React from 'react';
import {ListItemProps} from '../ui/list';
import {DifficultyLabel, Game} from '../../../service/api/game';
import {ArrowRightCircle, PencilSquare, Trash} from 'react-bootstrap-icons';
import {useHistory} from 'react-router-dom';

export const GameItem: React.FC<ListItemProps<Game>> = ({item, onDelete, onEdit}) => {
    const history = useHistory();

    return (
        <tr>
            <td>
                {item.id}
            </td>
            <td>
                {item.name}
            </td>
            <td>
                {DifficultyLabel[item.difficulty]}
            </td>
            <td>
                {item.isPublic ? "Yes" : "No"}
            </td>
            <td>
                {`${item.location.lat.toFixed(6)}, ${item.location.lon.toFixed(6)}`}
            </td>
            <td>
                <ArrowRightCircle className="mr-2 cursor-pointer"
                                  onClick={() => history.push('/stages', {game: item})}
                />
                <PencilSquare
                    className="mr-2 cursor-pointer"
                    onClick={onEdit}
                />
                <Trash className="cursor-pointer"
                       onClick={onDelete}
                />
            </td>
        </tr>
    );
};
