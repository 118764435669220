import React from 'react';
import { ContentForm } from './form';
import { Content } from '../../../service/api/content';
import { useLocation } from 'react-router-dom';

export const ContentEdit: React.FC = () => {
    const location = useLocation<{ initialValues: Content }>();
    const state = location.state?.initialValues;

    return (
        /*
                <ContentForm id={state.id}
                             initialValues={{
                                 type: 'Image'/!*state.type*!/,
                                 transforms: state.transforms,
                                 imageMarkerId: state.imageMarkerId,
                             }}
                />
        */
        <ContentForm
            id={state.id}
            initialValues={{
                type: state?.type,
                title: state?.title,
                hintText: state?.hintText,
                recordingKey: state?.recordingKey,
                order: state?.order,
                completeButtonLabel: state?.completeButtonLabel,
                autoContinueTimeout: state?.autoContinueTimeout,
                transforms: state?.transforms,
                imageMarkerId: state?.imageMarkerId ?? null,
                question: {
                    question: state?.question?.length ? state.question : 'Nothing',
                    answers: state?.answers ?? [],
                },
                objectId: state?.objectId ?? null,
                objectPlacement: state?.objectPlacement ?? null,
                model: state?.objectId ?? null,
                image: null,
                video: null,
            }}/>
    );
};
