import { Stage } from './stage';
import { Game } from './game';
import { Enum, GetAllData, Transforms, UpdateData } from './types';
import { AuthData } from '../auth';
import { request } from './request';
import { PagingParams } from './paging';

export const ContentType = {
    Question: 'Question',
    Image: 'Image',
    Video: 'Video',
    Model: 'Model',
} as const;

export type ContentTypes = Enum<typeof ContentType>;

export const ObjectPlacementType = {
    AIR: 'air',
    SURFACE: 'surface',
} as const;

export type ObjectPlacementTypes = Enum<typeof ObjectPlacementType>;

export type Content = {
    id: number,
    stageId: number,
    type: ContentTypes,
    title: string,
    hintText: string,
    recordingKey: string,
    order: number,
    completeButtonLabel: string,
    autoContinueTimeout: number | null,
    objectPlacement: ObjectPlacementTypes | null,

    transforms: Transforms,
    imageMarkerId?: number,
    objectId?: number,
    question?: string,
    answers?: Answer[],
};

export type Answer = {
    answer: string,
    position: number,
    isCorrect: boolean,
};

export type ContentDataCommon = {
    type: ContentTypes,
    title: string,
    hintText: string,
    recordingKey: string,
    order: number,
    completeButtonLabel: string,
    autoContinueTimeout: number | null,
    objectPlacement: ObjectPlacementTypes | null,

    transforms: Transforms,
    imageMarkerId?: number | null,
    objectId?: number | null,
    question?: string,
    answers?: Answer[],
};

/*type ContentDataModel = {
    type: typeof ContentType.Model,
    objectId: number,
};

type ContentDataQuestion = {
    type: typeof ContentType.Question,
    question: string,
    answers: Answer[],
};

type ContentDataImage = {
    type: typeof ContentType.Image,
};

type ContentDataVideo = {
    type: typeof ContentType.Video,
};*/

export type CreateContentData = ContentDataCommon;
// & (
// ContentDataModel |
// ContentDataQuestion |
// ContentDataImage |
// ContentDataVideo);

export type UpdateContentData = UpdateData<CreateContentData>;
export type GetAllContentData = GetAllData<Content>;

export type GetContentData = {
    id: number,
};

export const createContentApi = (game: Game, stage: Stage) => class ContentApi {
    static create(data: CreateContentData, auth: AuthData) {
        return request(`/game/${game.id}/stage/${stage.id}/content`)
            .post(data)
            .json()
            .auth(auth)
            .execute();
    }

    static getAll(params: PagingParams, auth: AuthData) {
        return request(`/game/${game.id}/stage/${stage.id}/content`)
            .get()
            .json()
            .params(params)
            .auth(auth)
            .execute() as Promise<GetAllContentData>;
    }

    static update({id, data}: UpdateContentData, auth: AuthData) {
        return request(`/game/${game.id}/stage/${stage.id}/content/${id}`)
            .put(data)
            .json()
            .auth(auth)
            .execute();
    }

    static delete({id}: GetContentData, auth: AuthData) {
        return request(`/game/${game.id}/stage/${stage.id}/content/${id}`)
            .delete()
            .auth(auth)
            .execute();
    }

};
