import React from 'react';
import { useLocation } from 'react-router-dom';
import { Stage } from '../../../service/api/stage';
import { StageForm } from './form';
import { Game } from '../../../service/api/game';

export const StageEdit: React.FC = () => {
    const location = useLocation<{ game: Game, initialValues: Stage }>();
    const state = location.state?.initialValues;

    return (
        <StageForm id={state.id}
                   game={location.state.game}
                   initialValues={{
                       name: state.name,
                       position: state.position,
                   }}
        />
    );
};
