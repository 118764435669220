import React from 'react';
import {GameForm} from './form';

export const GameCreate: React.FC = () => {
    return (
        <GameForm initialValues={{
            name: '',
            subtitle: '',
            difficulty: 1,
            description: '',
            location: {
                lat: 0,
                lon: 0,
            },
        }}/>
    );
};
