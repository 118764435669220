import {ComponentClass, FunctionComponent} from 'react';
import {WithRouterStatics} from 'react-router';

export interface Route {
    pathname: string,
    exact: boolean,
    component: FunctionComponent<any> | (ComponentClass<any> & WithRouterStatics<any>),
    title?: string,
}

type Routes = Route[];
export default Routes;

export const mapRoutesToComponent = (routes: Routes, component: FunctionComponent<any> | (ComponentClass<any> & WithRouterStatics<any>), groupTitle?: string): Routes => routes.map(
    route => ({
        ...route,
        component,
        title: groupTitle,
    }),
);

export const combineRoutes = (routes: Routes[]): Routes => routes.flat();
