import React from 'react';
import { StageForm } from './form';
import { useLocation } from 'react-router-dom';
import { Game } from '../../../service/api/game';

export const StageCreate: React.FC = () => {
    const location = useLocation<{ game: Game }>();

    return (
        <StageForm
            initialValues={{
                name: '',
                position: 0,
            }}
            game={
                location.state.game
            }/>
    );
};
