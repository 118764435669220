import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'assets/scss/index.scss';

import { NotFound } from './components/errors/404';
import { Home } from './components/home';
import { Login } from './components/login';

import Routes, { combineRoutes, mapRoutesToComponent } from './service/routes';
import { renderRedirect, renderRouter } from './service/router';

import { HomeRoutes } from './components/home/routes';
import { useDispatch, useSelector } from './hooks';
import { LoginSelector, refreshLogin, LoginAction} from './components/login/store';
import { Auth } from './service/auth';

const AppRoutes: Routes = combineRoutes([
    [{
        pathname: '/',
        exact: true,
        component: Login,
    }],
    [{
        pathname: '/404',
        exact: true,
        component: NotFound,
        title: '404',
    }],
    mapRoutesToComponent(HomeRoutes, Home),
]);

export const App: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const auth = useSelector(LoginSelector.auth);
    const [refreshed, setRefreshed] = useState(false);

    useEffect(() => {
        AppRoutes.every(route => {
            if (route.title && route.pathname === location.pathname) {
                document.title = AppRoutes[0].title === route.title ? route.title : `${document.title} - ${route.title}`;
                return false;
            } else {
                document.title = AppRoutes[0].title ?? 'AGGames CMS';
                return true;
            }
        });
    }, [location]);

    useEffect(() => {
        if (auth) {
            if (Auth.isExpired(auth)) {
                dispatch(LoginAction.logout());
                setRefreshed(false);
            }
            else if (!refreshed) {
                dispatch(refreshLogin());
                setRefreshed(true);
            }
        }
    }, [auth, dispatch, location, refreshed]);

    if (!auth || Auth.isExpired(auth)) {
        return (
            AppRoutes.findIndex(route => route.pathname === '/') === -1 ?
                renderRedirect('/') :
                renderRouter('appBody', AppRoutes)
        );
    }

    return (
        AppRoutes.findIndex(route => route.pathname === location.pathname) === -1 ?
            renderRedirect('/404') :
            renderRouter('appBody', AppRoutes)
    );
};
