import {useSelector} from '../../../hooks';
import {LoginSelector} from '../../login/store';
import {CreateObjectData, ObjectApi} from "../../../service/api/object";
import {ResourceApi} from "../../../service/api/resource";

export type UploadObject = {
    files: FileList | null,
    name: string,
    group: 'Image' | 'Video' | 'Model',
    caption?: string;
}

export const useObjects = () => {
    const auth = useSelector(LoginSelector.auth);

    const submitModel = async (values: UploadObject) => {
        //console.log('submitModel', values);

        if (!values.files) {
            console.warn('trying to sumbit, but null provided, this shouldnt happen lol');
            return;
        }

        let modelId = -1;
        let texturesIds: number[] = [];

        for (const file of values.files) {
            if (file.name.endsWith('.fbx')) {
                const newResource = await ResourceApi.createFbx({
                    file: file,
                    name: values.name,
                    group: 'Model',
                }, auth);

                if ((newResource as any).error === 'CONFLICT') {
                    alert('Please enter a unique name');
                    return;
                }

                modelId = newResource.id;
            } else {
                const newResource = await ResourceApi.create({
                    file: file,
                    name: `${values.name}__${file.name}`,  // because the group+name pair must be unique
                    group: 'Texture',
                }, auth);

                if ((newResource as any).error === 'CONFLICT') {
                    alert('Please enter a unique name');
                    return;
                }

                texturesIds.push(newResource.id);
            }
        }

        const objectUpload: CreateObjectData = {
            resourceIds: [modelId, ...texturesIds],
            data: {
                Model: {
                    animation: modelId,
                },
            },
        };

        await ObjectApi.create(objectUpload, auth);
        //console.log('model uploaded', newObject);
        alert('Object uploaded successfully!');
    };

    const submitImage = async (values: UploadObject) => {
        //console.log('submitImage', values);

        if (!values.files) {
            console.warn('trying to sumbit, but null provided, this shouldnt happen lol');
            return;
        }

        // ImageUpload has multiple={false}, so there's only one file
        const file: File = values.files[0];

        const newResource = await ResourceApi.create({
            file: file,
            name: values.name,
            group: 'Image',
        }, auth);

        //console.log('image resource uploaded', newResource);

        if ((newResource as any).error === 'CONFLICT') {
            alert('Please enter a unique name');
            return;
        }

        const objectUpload: CreateObjectData = {
            resourceIds: [newResource.id],
            data: {
                Image: {
                    caption: values.caption || '',
                },
            },
        };

        await ObjectApi.create(objectUpload, auth);
        //console.log('image uploaded', newObject);
        alert('Object uploaded successfully!');
    };

    const submitVideo = async (values: UploadObject) => {
        //console.log('submitVideo', values);

        if (!values.files) {
            console.warn('trying to sumbit, but null provided, this shouldnt happen lol');
            return;
        }

        // VideoUpload has multiple={false}, so there's only one file
        const file: File = values.files[0];

        const newResource = await ResourceApi.create({
            file: file,
            name: values.name,
            group: 'Video',
        }, auth);

        //console.log('video resource uploaded', newResource);

        if ((newResource as any).error === 'CONFLICT') {
            alert('Please enter a unique name');
            return;
        }

        const objectUpload: CreateObjectData = {
            resourceIds: [newResource.id],
            data: {
                Video: {
                    caption: values.caption || '',
                },
            },
        };

        await ObjectApi.create(objectUpload, auth);
        //console.log('video uploaded', newObject);
        alert('Object uploaded successfully!');
    };

    return {
        submitModel,
        submitImage,
        submitVideo,
    };
};
