import { useSelector } from '../../../../hooks';
import { LoginSelector } from '../../../login/store';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthData } from '../../../../service/auth';
import { GetAllData } from '../../../../service/api/types';

export type Indexable = {
    id: any,
    name?: any,
};

export type UseListGetArgs<T extends Indexable> = {
    onGetPage: (params: { page: number, limit: number }, auth: AuthData) => Promise<GetAllData<T>>,
    limit?: number,
};

export type UseListArgs<T extends Indexable> = UseListGetArgs<T> & {
    createUrl?: string,
    editUrl?: string,
    onCreate?: () => any,
    onConfirmDelete: (params: { id: any }, auth: AuthData) => Promise<Response>,
};

const emptyCall = () => () => {
};

export const useListGet = <T extends Indexable>({onGetPage, limit = 32}: UseListGetArgs<T>) => {
    const auth = useSelector(LoginSelector.auth);

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pages, setPages] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<T[]>([]);

    const getPage = useCallback(async (page = 1) => {
        if (page < 0)
            return;

        setIsLoading(true);
        const result = await onGetPage({page: page - 1, limit: limit}, auth);

        if (result.count)
            setCount(result.count);

        if (result.pages)
            setPages(result.pages);

        if (result.data && result.data.length) {
            setData([...result.data]);
            setIsLoading(false);
        } else {
            setPage(-1);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, onGetPage, page, data]);

    const getNextPage = useCallback(async () => {
        // console.log('next page', page, pages);
        if (page < pages) {
            setPage(page + 1);
            await getPage(page + 1);
        }
    }, [page, pages, getPage]);

    const getPrevPage = useCallback(async () => {
        // console.log('prev page', page, pages);
        if (page > 1) {
            setPage(page - 1);
            await getPage(page - 1);
        }
    }, [page, getPage]);

    useEffect(() => {
        if (!data.length)
            getPage();

    }, [data, getPage]);

    return {
        auth,

        count,
        pages,

        page,
        data,
        setData,
        isLoading,

        getPrevPage,
        getNextPage,
    };
};

export const useList = <T extends Indexable>(args: UseListArgs<T>) => {
    const {
        createUrl,
        editUrl,
        onCreate: onCreateOverride,
        onConfirmDelete,
    } = args;

    const history = useHistory<{}>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(-1);

    const {
        auth,

        count,
        pages,

        page,
        data,
        setData,
        isLoading,

        getPrevPage,
        getNextPage,
    } = useListGet(args);

    const deleteItem = useMemo(() => data.find(item => item.id === deleteId), [data, deleteId]);

    const onCreate = onCreateOverride ?
        onCreateOverride : (
            createUrl ?
                () => history.push(createUrl, history.location.state)
                : emptyCall
        );

    const onEdit =
        editUrl ?
            (id: number) => () =>
                history.push(
                    editUrl,
                    {
                        ...history.location.state,
                        initialValues: data.find(item => item.id === id),
                    },
                )
            : emptyCall;
    const onDelete = (id: number) => () => {
        setDeleteId(id);
        setShowDeleteModal(true);
    };

    const cancelDelete = () => setShowDeleteModal(false);
    const confirmDelete = async () => {
        const result = await onConfirmDelete({id: deleteId}, auth);

        if (result.status === 204)
            setData(data.filter(item => item.id !== deleteId));
        else
            alert('Request failed.');

        setShowDeleteModal(false);
    };

    return {
        count,
        pages,
        page,
        data,
        isLoading,

        getPrevPage,
        getNextPage,

        onCreate,
        onEdit,
        onDelete,

        deleteItem,
        showDeleteModal,
        cancelDelete,
        confirmDelete,
    };
};
