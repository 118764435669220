import React from 'react';
import {createTableList} from '../ui/list';
import {UserItem} from './item';
import {User, UserApi} from '../../../service/api/user';

const List = createTableList<User>();

export const UserList: React.FC = () => (
    <List title="Users"
          itemName="User"
          columns={[
              '#',
              'Username',
              'Name',
              'Age',
              'Blocked',
              'Actions',
          ]}
          createUrl="/users/create"
          editUrl="/users/edit"
          onGetPage={async (params, auth) =>
              await UserApi.getAll(params, auth)
          }
          onConfirmDelete={UserApi.delete}
          listItem={UserItem}
    />
);
