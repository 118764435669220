export interface AuthData {
    accessToken: string,
    refreshToken: string,
}

export class Auth {
    static isExpired(auth: AuthData) {
        if (!auth) return true;
        const expiry = this.info(auth).exp ?? 0;
        return Math.floor(Date.now() / 1000) > expiry;
    }

    static info(auth: AuthData) {
        return JSON.parse(atob(auth.accessToken.split('.')[1]));
    }
}
