import {Redirect, Route, Switch} from 'react-router-dom';
import React from 'react';
import Routes from './routes';

export const renderRedirect = (to: string) => (
    <Redirect to={to}/>
);

export const renderRouter = (keyPrefix: string, routes: Routes) => (
    <Switch>
        {routes.map((route, key) => (
            <Route key={`${keyPrefix}-${key}`}
                   exact={route.exact || false}
                   path={route.pathname}
                   component={route.component}
            />
        ))}
    </Switch>
);
