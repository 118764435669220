import { request } from './request';
import {AuthData} from '../auth';
import {GetAllData, UpdateData} from "./types";
import {PagingParams} from "./paging";
import {Resource} from "./resource";

export type ObjectData = {
    Image?: {caption: string},
    Video?: {caption: string},
    Model?: {animation: number}
}

export type Object = {
    id: number,
    resources: Resource[],
    data: ObjectData
}

export type CreateObjectData = {
    resourceIds: number[],
    data: ObjectData
}

export type GetObjectData = {
    id: number,
}

export type UpdateObjectData = UpdateData<CreateObjectData>;
export type GetAllObjectsData = GetAllData<Object>;

export class ObjectApi {
    /**
     * Registers a new object
     */
    static create(data: CreateObjectData, auth: AuthData): Promise<Object> {
        return request('/object')
            .post(data)
            .json()
            .auth(auth)
            .execute() as Promise<Object>;
    }

    /**
     * Fetches paginated list of objects
     */
    static getAll(params: PagingParams, auth: AuthData) {
        return request(`/object/all`)
            .get()
            .json()
            .params(params)
            .auth(auth)
            .execute() as Promise<GetAllObjectsData>;
    }

    /**
     * Fetches one object
     */
    static get({id}: GetObjectData, auth: AuthData) {
        return request(`/object/${id}`)
            .get()
            .auth(auth)
            .json()
            .execute() as Promise<Object>;
    }

    /**
     * Updates object
     */
    static update({id, data}: UpdateObjectData, auth: AuthData) {
        return request(`/object/${id}`)
            .put(data)
            .json()
            .auth(auth)
            .execute();
    }

    /**
     * Deletes one object
     */
    static delete({id}: GetObjectData, auth: AuthData) {
        return request(`/object/${id}`)
            .delete()
            .auth(auth)
            .execute();
    }
}
