export interface Error {
    error: string,
    message: string,
}

export const getErrors = (result: any) => {
    if (result.hasOwnProperty('error') && result.hasOwnProperty('message')) {
        return (result as Error);
    }
    return null;
}
