import React from 'react';
import {ObjectForm} from './form';

export const ObjectCreate: React.FC = () => {
    return (
        <ObjectForm initialValues={{
            files: null,
            group: 'Image',
            name: ''
        }}/>
    );
};
