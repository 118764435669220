import React from 'react';
import { createTableList } from '../ui/list';
import { ObjectItem } from './item';
import { Object, ObjectApi } from '../../../service/api/object';

const List = createTableList<Object>();

export const ObjectList: React.FC = () => (
    <List title="Objects"
          itemName="Object"
          columns={[
              '#',
              'Name',
              'No. of resources',
              'Type',
              'Actions',
          ]}
          createUrl="/objects/create"
          onGetPage={async (params, auth) =>
          {
              return await ObjectApi.getAll(params, auth);
          }
          }
          onConfirmDelete={ObjectApi.delete}
          listItem={ObjectItem}
    />
);
