import React from 'react';
import { ListItemProps } from '../ui/list';
import { Object as AggamesObject } from '../../../service/api/object';
import { Trash } from 'react-bootstrap-icons';

export const ObjectItem: React.FC<ListItemProps<AggamesObject>> = ({item, onDelete, onEdit}) => {
    const ObjectType = Object.keys(item.data).length === 0 ? '' : Object.keys(item.data)[0];

    const getObjectName = () => {
        const resources = item.resources.filter(r => !r.name.includes('.') || r.name.includes('vrx'))

        if (!resources.length)
            return item.resources[0].name

        return resources[0].name;
    }

    return (
        <tr>
            <td>
                {item.id}
            </td>
            <td>
                {getObjectName()}
            </td>
            <td>
                {item.resources.length}
            </td>
            <td>
                {ObjectType}
            </td>
            <td>
                {/*<PencilSquare*/}
                {/*    className="mr-2 cursor-pointer"*/}
                {/*    onClick={onEdit}*/}
                {/*/>*/}
                <Trash className="cursor-pointer"
                       onClick={onDelete}
                />
            </td>
        </tr>
    );
};
