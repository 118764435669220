import React from 'react';
import { Form as FormikForm, Formik } from 'formik';

import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FormValues, useLogin } from './hooks';
import logoImage from '../../assets/images/logo_180x180.png';

export const Login: React.FC = props => {
    const {onSubmit} = useLogin();

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm={10} md={8} lg={6} xl={5}>
                    <h2 className="text-center m-5">
                        <img src={logoImage}
                             className="d-inline-block align-top mr-3"
                             height={30}
                             alt="logo"
                        />
                        AGGames
                    </h2>

                    <Formik initialValues={{
                        email: '',
                        password: '',
                    } as FormValues}
                            onSubmit={onSubmit}
                    >
                        {({isSubmitting, errors, status, values, handleChange}) => (
                            <FormikForm noValidate={true}>
                                <Form.Group controlId="email">
                                    <Form.Label>
                                        Email
                                    </Form.Label>
                                    <Form.Control type="email"
                                                  name="email"
                                                  value={values.email}
                                                  onChange={handleChange}
                                                  isInvalid={status || errors.email}
                                    />
                                    {errors.email && (
                                        <Form.Text className="invalid-feedback">
                                            {errors.email}
                                        </Form.Text>
                                    )}
                                </Form.Group>

                                <Form.Group controlId="password" className="mb-4">
                                    <Form.Label>
                                        Password
                                    </Form.Label>
                                    <Form.Control type="password"
                                                  name="password"
                                                  value={values.password}
                                                  onChange={handleChange}
                                                  isInvalid={status || errors.password}
                                    />
                                    {errors.password && (
                                        <Form.Text className="invalid-feedback">
                                            {errors.password}
                                        </Form.Text>
                                    )}
                                </Form.Group>

                                <Form.Group className="text-danger pb-4 mb-0">
                                    {status}
                                </Form.Group>

                                <Button className="w-100"
                                        variant="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                >
                                    Login
                                </Button>
                            </FormikForm>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};
