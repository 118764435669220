import { request } from './request';
import {AuthData} from '../auth';
import {GetAllData, UpdateData} from "./types";
import {PagingParams} from "./paging";

export type User = {
    id: number,
    username: string,
    name: string,
    age: number,
    picture: string,
    blocked: number,
}

export type UserInfoData = {
    username: string,
    name: string,
    age: number,
    picture: File | null,
    blocked: number,
}

export type CreateUserData = {
    username: string,
    email: string,
    password: string,
}

export type GetUserData = {
    id: number,
}

export type UpdateUserData = UpdateData<UserInfoData>;
export type GetAllUserData = GetAllData<User>;

export class UserApi {
    /**
     * Registers a new user
     */
    static create(data: CreateUserData, auth: AuthData) {
        return request('/auth/register')
            .post(data)
            .json()
            .auth(auth)
            .execute();
    }
    /**
     * Fetches paginated list of users
     */
    static getAll(params: PagingParams, auth: AuthData) {
        return request(`/user/all`)
            .get()
            .json()
            .params(params)
            .auth(auth)
            .execute() as Promise<GetAllUserData>;
    }

    /**
     * Fetches one user
     */
    static get({id}: GetUserData, auth: AuthData) {
        return request(`/user/${id}`)
            .get()
            .auth(auth)
            .json()
            .execute() as Promise<User>;
    }

    /**
     * Fetches profile picture of user
     */
    static getProfilePicture({id}: GetUserData, auth: AuthData) {
        return request(`/user/picture/${id}`)
            .get()
            .auth(auth)
            .execute();
    }

    /**
     * Updates user
     */
    static update({id, data}: UpdateUserData, auth: AuthData) {
        const formData = new FormData();
        formData.append('username', data.username);
        formData.append('name', data.name);
        formData.append('age', data.age ? data.age.toString() : '');
        formData.append('blocked', data.blocked.toString());
        formData.append('picture', data.picture ? data.picture : '');

        return request(`/user/${id}`)
            .putFormData(formData)
            .json()
            .auth(auth)
            .execute();
    }

    /**
     * Deletes one user
     */
    static delete({id}: GetUserData, auth: AuthData) {
        return request(`/user/${id}`)
            .delete()
            .auth(auth)
            .execute();
    }
}