import React from 'react';
import {renderRedirect, renderRouter} from '../../service/router';

import {useSelector} from '../../hooks';
import {LoginSelector} from '../login/store';
import {Container} from 'react-bootstrap';
import {HomeNavbar} from './navbar';
import {HomeRoutes} from './routes';

const router = renderRouter('home', HomeRoutes);

export const Home: React.FC = () => {
    const auth = useSelector(LoginSelector.auth);

    if (!auth)
        return renderRedirect('/');

    return (
        <>
            <HomeNavbar/>
            <Container>
                {router}
            </Container>
        </>
    );
};
