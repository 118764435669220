import React, { useEffect, useMemo, useState } from 'react';
import { useListGet, UseListGetArgs } from '../list/hooks';
import { Resource } from '../../../../service/api/resource';
import { Col, Form } from 'react-bootstrap';
import { FormikErrors, FormikValues } from 'formik';
import { RawMarkerItem } from '../../markers/item';
import { ArrowLeftCircle, ArrowRightCircle } from 'react-bootstrap-icons';

type MarkerInputProps<T extends FormikValues> = UseListGetArgs<Resource> & {
    controlId?: string,
    label: string,
    name: keyof T & string,
    values: T,
    errors: FormikErrors<T>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    limit?: number,
};

export const createMarkerInput = <T extends FormikValues>(): React.FC<MarkerInputProps<T>> => {
        return (props) => {
            const [updateIndex, setUpdateIndex] = useState(-1);

            const {
                controlId,
                label,
                name,
                values,
                errors,
                setFieldValue,
            } = props;

            const {
                pages,
                getPrevPage,
                getNextPage,
                page,
                data,
            } = useListGet<Resource>(props);

            useEffect(() => {
                data.length && setFieldValue(name, updateIndex < 0 ? null : updateIndex >= data.length ? data[index].id : data[updateIndex].id);
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [data, updateIndex]);

            const index = useMemo(() => data.findIndex(item => item.id === values[name]), [data, values, name]);

            const setPrevious = async () => {
                let newIndex = -1;
                if (index <= 0 && page > 1) {
                    await getPrevPage();
                    newIndex = data.length - 1;
                } else {
                    if (index > 0)
                        newIndex = index - 1;
                }
                setUpdateIndex(newIndex);
            };

            const setNext = async () => {
                let newIndex = data.length - 1;
                if (index >= data.length - 1 && page < pages) {
                    await getNextPage();
                    newIndex = 0;
                } else {
                    if (index < data.length - 1)
                        newIndex = index + 1;
                }
                setUpdateIndex(newIndex);
            };

            return (
                <Form.Group controlId={controlId}>
                    <Form.Label className="d-flex align-items-center">
                        {label}

                        <div className="flex-grow-1"/>

                        <ArrowLeftCircle className="mr-2 cursor-pointer"
                                         onClick={setPrevious}
                        />
                        <ArrowRightCircle className="cursor-pointer"
                                          onClick={setNext}
                        />
                    </Form.Label>
                    <Form.Row>
                        <Col>
                            <div className="w-100 d-flex align-items-stretch overflow-hidden">
                                <div className="w-100 d-flex align-items-stretch position-relative transition-left"
                                     style={{left: `${-110 * index}%`}}
                                >
                                    {data.map(item => (
                                        <div key={item.id} className="w-100 flex-grow-0 flex-shrink-0"
                                             style={{marginRight: '10%'}}
                                        >
                                            <RawMarkerItem item={item}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Form.Row>
                    {errors[name] && (
                        <Form.Text className="invalid-feedback">
                            {errors[name]}
                        </Form.Text>
                    )}
                </Form.Group>
            );
        };
    }
;
