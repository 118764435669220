import React from 'react';
import { ListItemProps } from '../ui/list';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
//import { useHistory } from 'react-router-dom';
import { Content } from '../../../service/api/content';

export const ContentItem: React.FC<ListItemProps<Content>> = ({item, onDelete, onEdit}) => {
    //const history = useHistory<{}>();

    return (
        <tr>
            <td>
                {item.id} - {item.title}
            </td>
            <td>
                {item.order}
            </td>
            <td>
                {item.type}
            </td>
            <td>
                {/*<ArrowRightCircle className="cursor-pointer"*/}
                {/*                  onClick={() => history.push(*/}
                {/*                      '/contents',*/}
                {/*                      {*/}
                {/*                          ...history.location.state,*/}
                {/*                          stage: item,*/}
                {/*                      },*/}
                {/*                  )}*/}
                {/*/>*/}
                <PencilSquare
                    className="mr-2 cursor-pointer"
                    onClick={onEdit}
                />
                <Trash className="cursor-pointer"
                       onClick={onDelete}
                />
            </td>
        </tr>
    );
};
