import { Indexable, useList, UseListArgs, UseListGetArgs } from './hooks';
import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { PlusLg } from 'react-bootstrap-icons';
import { Loading } from '../loading';
import { BackButton } from '../../../ui/actions/back';

export type GenericListContainerProps<T extends Indexable> = ListProps<T> & {
    count: number,
    pages: number,
    page: number,
    data: T[],
    isLoading: boolean,

    getPrevPage: () => any,
    getNextPage: () => any,

    onCreate: () => void,
    onEdit: (id: number) => () => void,
    onDelete: (id: number) => () => void,
};
export type GenericListContainer<T extends Indexable> = React.FC<GenericListContainerProps<T>>;

export type GetListItemProps<T extends Indexable> = {
    item: T,
    onEdit?: () => any,
    onDelete?: () => any,
};

export type ListItemProps<T extends Indexable> = GetListItemProps<T> & {
    onEdit: () => any,
    onDelete: () => any,
};

export type GetListProps<T extends Indexable> = UseListGetArgs<T> & {
    listItem: React.FC<GetListItemProps<T>>,
};

export type ListProps<T extends Indexable> = Omit<GetListProps<T>, 'listItem'> & UseListArgs<T> & {
    itemName: string,
    title: string,
    listItem: React.FC<ListItemProps<T>>,
};

export const createGenericList = <T extends Indexable>(Container: GenericListContainer<T>): React.FC<ListProps<T>> => {
    return (props) => {
        const list = useList(props);
        const {
            page,
            data,

            onCreate,

            deleteItem,
            showDeleteModal,
            cancelDelete,
            confirmDelete,
        } = list;

        const {itemName, title} = props;

        return (
            <>
                <Row>
                    <Col className="pt-3 d-flex align-items-center">

                        <BackButton/>

                        <h3 className="m-0">
                            {title}
                        </h3>

                        <div className="flex-grow-1"/>

                        <Button variant="primary"
                                className="p-2 btn-icon"
                                onClick={onCreate}
                        >
                            <PlusLg/>
                        </Button>
                    </Col>
                </Row>

                <Row className="position-relative">
                    <Col className="pt-3 align-items-center">
                    {page !== 0 ?
                        (data.length ? (
                                
                                <Col className="position-absolute"
                                        style={{left: 0, top: 0, right: 0}}
                                >
                                    <Container {...props}
                                                {...list}
                                    />
                                </Col>
                            ) : (
                                <div className="col position-absolute d-flex justify-content-center m-5"
                                        style={{left: 0, top: 0, right: 0}}
                                >
                                    <h5>
                                        There are no items yet!
                                    </h5>
                                </div>
                            )
                        ) : (
                            
                            <Loading/>
                        )
                    }
                    </Col>
                </Row>

                <Modal show={showDeleteModal} onHide={cancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Delete {itemName}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete {deleteItem?.name || itemName.toLowerCase()}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={cancelDelete}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary"
                                onClick={confirmDelete}
                        >
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };
};
