import React from 'react';
import {Col, Form, InputGroup} from 'react-bootstrap';
import {FormikErrors, FormikValues} from 'formik';
import {Coords} from '../../../service/api/types';

type CoordsInputProps<T extends FormikValues> = {
    controlId?: string,
    label: string,
    name: keyof T & string,
    values: T,
    errors: FormikErrors<T>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
};

export const createCoordsInput: <T extends FormikValues>() => React.FC<CoordsInputProps<T>> = () => {
    return ({controlId, label, name, values, errors, setFieldValue}) => {
        const value = values[name];
        const error = errors[name] as { lat: string, lon: string } | undefined;
        const setCoords = (type: keyof Coords, event: React.ChangeEvent<any>) =>
            setFieldValue(name, {...value, [type]: event.target.value});

        return (
            <Form.Group controlId={controlId}>
                <Form.Label>
                    {label}
                </Form.Label>
                <Form.Row>
                    <Col>
                        <InputGroup hasValidation>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Lat
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="number"
                                          name={name}
                                          value={value.lat}
                                          onChange={event => setCoords('lat', event)}
                                          isInvalid={!!error?.lat}
                                          step={0.000001}
                            />
                            {error?.lat && (
                                <Form.Text className="invalid-feedback">
                                    {error.lat}
                                </Form.Text>
                            )}
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup hasValidation>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Lon
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="number"
                                          name={name}
                                          value={values[name].lon}
                                          onChange={event => setCoords('lon', event)}
                                          isInvalid={!!error?.lon}
                                          step={0.000001}
                            />
                            {error?.lon && (
                                <Form.Text className="invalid-feedback">
                                    {error.lon}
                                </Form.Text>
                            )}
                        </InputGroup>
                    </Col>
                </Form.Row>
            </Form.Group>
        );
    };
};
