import React from 'react';
import {UserCreateForm} from './form';

export const UserCreate: React.FC = () => {
    return (
        <UserCreateForm initialValues={{
            username: '',
            email: '',
            password: '',
        }}/>
    );
};
