import React from 'react';
import { createTableList } from '../ui/list';
import { StageItem } from './item';
import { useLocation } from 'react-router-dom';
import { Game } from '../../../service/api/game';
import { Stage, useStageApi } from '../../../service/api/stage';

const List = createTableList<Stage>();

export const StageList: React.FC = () => {
    const location = useLocation<{ game: Game }>();

    const StageApi = useStageApi(location.state.game);

    return (
        <List title="Stages"
              itemName="Stage"
              columns={[
                  '#',
                  'Name',
                  'Position',
                  'Actions',
              ]}
              createUrl="/stages/create"
              editUrl="/stages/edit"
              onGetPage={async (params, auth) => {
                  const result = await StageApi.getAll(params, auth);
                  result.data?.sort((a, b) => a.position - b.position);
                  return result;
              }}
              onConfirmDelete={StageApi.delete}
              listItem={StageItem}
        />
    );
};
