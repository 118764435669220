import {request} from './request';
import {AuthData} from '../auth';
import {PagingParams} from './paging';
import {GetAllData, UpdateData} from './types';

export type ResourceGroup = 'Marker' | 'Image' | 'Video' | 'Model';

export type Resource = {
    id: number,
    name: string,
    group: ResourceGroup,
    path: string,
    filename: string,
};

export type CreateResourceData = {
    file: File,
    name: string,
    group: string,
};

export type UpdateResourceData = UpdateData<CreateResourceData>;
export type GetAllResourceData = GetAllData<Resource>;

export type GetResourceData = {
    id: number,
};

export class ResourceApi {
    static create({file, name, group}: CreateResourceData, auth: AuthData): Promise<Resource> {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('group', group);
        formData.append('data', file);

        return request('/resource')
            .postFormData(formData)
            .json()
            .auth(auth)
            .execute() as Promise<Resource>;
    }

    static createFbx({file, name, group}: CreateResourceData, auth: AuthData): Promise<Resource> {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('group', group);
        formData.append('data', file);

        return request('/resource/fbx')
            .postFormData(formData)
            .json()
            .auth(auth)
            .execute() as Promise<Resource>;
    }

    static getAllByGroup(group: ResourceGroup, params: PagingParams, auth: AuthData): Promise<GetAllResourceData> {
        return request(`/resource/group/${group}`)
            .get()
            .json()
            .params(params)
            .auth(auth)
            .execute() as Promise<GetAllResourceData>;
    }

    static getResourceById({id}: GetResourceData, auth: AuthData) {
        return request(`/resource/${id}`)
            .get()
            .auth(auth)
            .execute();
    }

    static getResourceByPath(path: string, auth: AuthData) {
        return request(`/resource/path/${path}`)
            .get()
            .auth(auth)
            .execute();
    }

    static update({id, data}: UpdateResourceData, auth: AuthData) {
        const {file, name, group} = data;
        const formData = new FormData();
        formData.append('name', name);
        formData.append('group', group);
        formData.append('data', file);

        return request(`/resource/${id}`)
            .putFormData(formData)
            .json()
            .auth(auth)
            .execute();
    }

    static delete({id}: GetResourceData, auth: AuthData) {
        return request(`/resource/${id}`)
            .delete()
            .auth(auth)
            .execute();
    }
}
