import React, { useEffect, useState } from 'react';
import { MarkerForm, MarkerValues } from './form';
import { useLocation } from 'react-router-dom';
import { Resource, ResourceApi } from '../../../service/api/resource';
import { useSelector } from '../../../hooks';
import { LoginSelector } from '../../login/store';
import { Loading } from '../ui/loading';
import { renderRedirect } from '../../../service/router';

export const MarkerEdit: React.FC = () => {
    const auth = useSelector(LoginSelector.auth);
    const location = useLocation<{ initialValues: Resource }>();
    const state = location.state?.initialValues;
    const [isLoading, setIsLoading] = useState(true);
    const [file, setFile] = useState<File | null>(null);

    const loadImage = async () => {
        const result = await ResourceApi.getResourceById({id: state.id}, auth);
        const data = new File([await result.blob()], state.path);
        //console.log(data);
        setFile(data);
        setIsLoading(false);
    };

    useEffect(() => {
        if (state)
            loadImage();
    });

    if (!state)
        return renderRedirect('/404');

    const initialValues: MarkerValues = {
        files: file ? [file] : null,
        group: 'Marker',
        name: state.name,
    };

    if (isLoading)
        return <Loading/>;

    return (
        <MarkerForm id={state.id}
                    initialValues={initialValues}
        />
    );
};
