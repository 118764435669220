import {GameList} from './list';
import {GameCreate} from './create';
import {GameEdit} from './edit';

export const GamesRoutes = [
    {
        pathname: '/games',
        exact: true,
        component: GameList,
        title: 'Games',
        menuEntry: true,
    },
    {
        pathname: '/games/create',
        exact: true,
        component: GameCreate,
        title: 'Games',
        menuEntry: false,
    },
    {
        pathname: '/games/edit',
        exact: true,
        component: GameEdit,
        title: 'Games',
        menuEntry: false,
    },
];
