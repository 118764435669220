import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from '../../../../../hooks'
import { LoginSelector } from '../../../../login/store';
import { ResourceApi } from '../../../../../service/api/resource';
import { ModelPreview as RawModelPreview } from '../../../../ui/model-preview';

export type PreviewProps = {
    resourcePath: string;
};

const usePreview = (props: PreviewProps) => {
    const {
        resourcePath,
    } = props;

    const auth = useSelector(LoginSelector.auth);

    const [srcBlobUrl, setSrcBlobUrl] = useState('');

    useEffect(() => {
        (async () => {
            const result = await ResourceApi.getResourceByPath(resourcePath, auth);
            setSrcBlobUrl(URL.createObjectURL(await result.blob()));
        })();
    }, [resourcePath, auth]);

    return {
        srcBlobUrl,
    };
};

export const ImagePreview: React.FC<PreviewProps> = props => {
    const {
        srcBlobUrl,
    } = usePreview(props);

    return (
        <Card.Img variant="top" src={srcBlobUrl} />
    );
};

export const VideoPreview: React.FC<PreviewProps> = props => {
    const {
        srcBlobUrl,
    } = usePreview(props);

    const video = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        return () => {
            if (video.current) {
                video.current.pause();
                video.current.load();
            }
        }
    }, [video]);

    return (
        <video ref={video} controls={true} src={srcBlobUrl} />
    );
};

export const ModelPreview: React.FC<PreviewProps> = props => {
    const {
        srcBlobUrl,
    } = usePreview(props);

    const [dataUrl, setDataUrl] = useState('');

    useEffect(() => {
        //console.log({srcBlobUrl});
        (async () => {
            // The Three.js FBXLoader (and thus RawModelPreview) takes a data: url,
            // but our srcBlobUrl is a blob: url.
            // So we first convert the blob: url to a Blob using fetch() .blob()
            // and then we convert the Blob to a data: url using FileReader.
            // There has to be a better way, but I haven't find it.
            const reader = new FileReader();

            reader.onloadend = event => {
                const result = event.target?.result as string || '';

                // the data: url sometimes contains an html document
                // the reason is unknown
                if (result.startsWith('data:text/html'))
                    return;

                setDataUrl(event.target?.result as string || '');
            };

            reader.readAsDataURL(await fetch(srcBlobUrl).then(res => res.blob()));
        })();
    }, [srcBlobUrl]);

    /*useEffect(() => {
        console.log({dataUrl});
    }, [dataUrl]);*/

    if (!dataUrl) {
        return null;
    }

    return (
        <RawModelPreview modelUrl={dataUrl} />
    );
};

export const previews = {
    'Image': ImagePreview,
    'Video': VideoPreview,
    'Model': ModelPreview,
};
