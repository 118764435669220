import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { createFileUpload } from '../../ui/input/file-upload';
import { createTextInput } from '../../ui/input/text';
import { FormikHelpers } from 'formik/dist/types';
import { CreateResourceData, ResourceApi } from '../../../service/api/resource';
import { useSelector } from '../../../hooks';
import { LoginSelector } from '../../login/store';
import { useHistory } from 'react-router-dom';
import { FormContainer } from '../ui/form/container';

export type MarkerValues = {
    files: File[] | null,
    name: string,
    group: 'Marker',
};

type MarkerFormProps = {
    id?: number,
    initialValues: MarkerValues,
};

const TextInput = createTextInput<MarkerValues>();
const ImageUpload = createFileUpload<MarkerValues>('image');

export const MarkerForm: React.FC<MarkerFormProps> = ({initialValues, id}) => {
    const auth = useSelector(LoginSelector.auth);
    const history = useHistory();

    const onSubmit = async (values: MarkerValues, {setSubmitting, setFieldError}: FormikHelpers<MarkerValues>) => {
        if (!values.files || !values.files[0]) {
            setFieldError('file', 'Image is required');
            setSubmitting(false);
            return;
        }

        const data = {
            ...values,
            file: values.files[0],
        } as CreateResourceData;

        const apiCall = id ?
            () => ResourceApi.update({id, data}, auth) :
            () => ResourceApi.create(data, auth);

        const result = await apiCall();
        if (result.error) {
            if (result.message)
                alert(result.message);
        } else
            history.goBack();
    };

    return (
        <FormContainer title="Markers">
            <Formik initialValues={initialValues}
                    onSubmit={onSubmit}
            >
                {({errors, isSubmitting, values, handleChange, setFieldValue}) => (
                    <FormikForm className="py-3">
                        <Row className="justify-content-center">
                            <Col md={10} lg={6} xl={5}>
                                <ImageUpload controlId="files"
                                             label="Marker Image"
                                             name="files"
                                             values={values}
                                             errors={errors}
                                             setFieldValue={setFieldValue}
                                />
                            </Col>
                            <Col md={10} lg={6} xl={5}>
                                <TextInput controlId="name"
                                           label="Name"
                                           name="name"
                                           values={values}
                                           errors={errors}
                                           handleChange={handleChange}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3 mt-lg-0">
                            <Col md={1} lg={8} xl={8}/>
                            <Col md={10} lg={4} xl={3}
                                 className="d-flex justify-content-end"
                            >
                                <Button
                                    className="w-50 mr-3"
                                    variant="outline-secondary"
                                    type="reset"
                                    disabled={isSubmitting}
                                >
                                    Clear
                                </Button>
                                <Button
                                    className="w-50"
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </FormikForm>
                )}
            </Formik>
        </FormContainer>
    );
};
