import {FormikErrors, FormikValues} from 'formik';
import React from 'react';
import {Button, Col, Form, InputGroup} from 'react-bootstrap';
import {Answer} from '../../../service/api/content';
import {ArrowDownCircle, ArrowUpCircle, Check2, PlusLg, Trash, XCircle} from 'react-bootstrap-icons';

type AnswersInputProps<T extends FormikValues> = {
    controlId?: string,
    label: string,
    name: keyof T & string | string,
    values: T,
    errors: FormikErrors<T>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
};

const indexInto = (object: Record<string, any>, key: string): any =>
    object && key.split('.').reduce((walk, key) => walk[key], object);

export const createAnswersInput: <T extends FormikValues>() => React.FC<AnswersInputProps<T>> = () => {
    return ({controlId, label, name, values, errors, setFieldValue}) => {
        const value = (indexInto(values, name) as Answer[]).sort((a, b) => a.position - b.position);
        const error = errors[name];

        const setValue = (id: number, item: Answer) => {
            const newValue = [...value];
            newValue[id] = item;
            setFieldValue(name, newValue);
        };

        const toggleIsCorrect = (id: number, value: Answer) => () => setValue(
            id,
            {...value, isCorrect: !value.isCorrect},
        );
        const setAnswer = (id: number, value: Answer) => (event: React.ChangeEvent<any>) => setValue(
            id,
            {...value, answer: event.target.value!},
        );

        const onCreate = () => setFieldValue(name, [
            ...value,
            {answer: '', isCorrect: false, position: value.length} as Answer,
        ]);
        const onDelete = (id: number) => () => {
            setFieldValue(
                name,
                value.filter((_, n) => id !== n)
                    .map((item, n) => ({...item, position: n})),
            );
        };

        const onMoveUp = (id: number) => () => {
            const newValue = [...value];

            newValue[id - 1].position = id;
            newValue[id].position = id - 1;

            setFieldValue(name, newValue);
        };
        const onMoveDown = (id: number) => () => {
            const newValue = [...value];

            newValue[id].position = id + 1;
            newValue[id + 1].position = id;

            setFieldValue(name, newValue);
        };

        return (
            <Form.Group controlId={controlId}>
                <Form.Label className="d-flex align-items-center">
                    {label}

                    <div className="flex-grow-1"/>

                    <Button variant="primary"
                            className="p-1 btn-icon"
                            onClick={onCreate}
                    >
                        <PlusLg/>
                    </Button>
                </Form.Label>
                    {value.map((item, id) => (
                            <Form.Row key={`answer-${id}`}>
                                <Col>
                                    <InputGroup hasValidation className="my-1">
                                        <InputGroup.Prepend>
                                            <Button variant={item.isCorrect ? 'success' : 'primary'}
                                                    onClick={toggleIsCorrect(id, item)}
                                                    className=""
                                            >
                                                {item.isCorrect ? (
                                                    <Check2/>
                                                ) : (
                                                    <XCircle/>
                                                )}
                                            </Button>
                                        </InputGroup.Prepend>
                                        <Form.Control type="text"
                                                      name={name}
                                                      value={item.answer}
                                                      onChange={setAnswer(id, item)}
                                                      isInvalid={!!error}
                                        />
                                        <InputGroup.Append>
                                            <Button onClick={onMoveUp(id)}
                                                    disabled={id <= 0}
                                            >
                                                <ArrowUpCircle className="cursor-pointer"
                                                               onClick={() => {
                                                               }}
                                                />
                                            </Button>
                                            <Button onClick={onMoveDown(id)}
                                                    disabled={id >= value.length - 1}
                                            >
                                                <ArrowDownCircle/>
                                            </Button>
                                            <Button onClick={onDelete(id)}>
                                                <Trash/>
                                            </Button>
                                        </InputGroup.Append>

                                        {error && (
                                            <Form.Text className="invalid-feedback">
                                                {error}
                                            </Form.Text>
                                        )}
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                    ))}
            </Form.Group>
        );
    };
};
