import React from 'react';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { FormContainer } from '../ui/form/container';
import { ContentType } from '../../../service/api/content';
import { createFileUpload } from '../../ui/input/file-upload';
import { createTextInput } from 'components/ui/input/text';
import { useObjects, UploadObject } from './hooks';

type ObjectFormProps = {
    id?: number,
    initialValues: UploadObject,
};

const ImageUpload = createFileUpload<UploadObject>('image');
const VideoUpload = createFileUpload<UploadObject>('video');
const ModelUpload = createFileUpload<UploadObject>('model');
const TextInput = createTextInput<UploadObject>();

export const ObjectForm: React.FC<ObjectFormProps> = ({initialValues, id}) => {
    const {submitModel, submitImage, submitVideo} = useObjects();

    const onSubmit = async (values: UploadObject, {setSubmitting, setFieldError}: FormikHelpers<UploadObject>) => {
        if (values.group === 'Image') {
            await submitImage(values);
        } else if (values.group === 'Video') {
            await submitVideo(values);
        } else if (values.group === 'Model') {
            await submitModel(values);
        }
    };

    return (
        <FormContainer title="Objects">
            <Formik initialValues={initialValues}
                    onSubmit={onSubmit}
            >
                {({errors, isSubmitting, values, handleChange, setFieldValue}) => (
                    <FormikForm className="py-3">
                        <Row className="justify-content-center">
                            <Col md={10} lg={6} xl={5}>
                                <Tabs id="type"
                                      variant="tabs"
                                      className="w-100"
                                      onSelect={key => setFieldValue('group', key)}
                                >
                                    <Tab title={ContentType.Image}
                                         eventKey={ContentType.Image}
                                         className="py-3"
                                    >
                                        <ImageUpload label="Image"
                                                     name="files"
                                                     values={values}
                                                     errors={errors}
                                                     setFieldValue={setFieldValue}
                                        />
                                    </Tab>
                                    <Tab title={ContentType.Video}
                                         eventKey={ContentType.Video}
                                         className="py-3"
                                    >
                                        <VideoUpload label="Video"
                                                     name="files"
                                                     values={values}
                                                     errors={errors}
                                                     setFieldValue={setFieldValue}
                                        />
                                    </Tab>
                                    <Tab title={ContentType.Model}
                                         eventKey={ContentType.Model}
                                         className="py-3"
                                    >
                                        <ModelUpload label="Model"
                                                     name="files"
                                                     values={values}
                                                     multiple={true}
                                                     errors={errors}
                                                     setFieldValue={setFieldValue}
                                        />
                                    </Tab>
                                </Tabs>
                            </Col>
                            <Col md={10} lg={6} xl={5}>
                                <TextInput controlId="name"
                                           label="Name"
                                           name="name"
                                           values={values}
                                           errors={errors}
                                           handleChange={handleChange}
                                />
                                {(values.group === 'Image' || values.group === 'Video') && (
                                    <TextInput controlId="caption"
                                               label="Caption"
                                               name="caption"
                                               values={values}
                                               errors={errors}
                                               handleChange={handleChange}
                                    />
                                )}
                            </Col>
                        </Row>

                        <Row className="mt-3 mt-lg-0">
                            <Col md={1} lg={8} xl={8}/>
                            <Col md={10} lg={4} xl={3}
                                 className="d-flex justify-content-end"
                            >
                                <Button
                                    className="w-50 mr-3"
                                    variant="outline-secondary"
                                    type="reset"
                                    disabled={isSubmitting}
                                >
                                    Clear
                                </Button>
                                <Button
                                    className="w-50"
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </FormikForm>
                )}
            </Formik>
        </FormContainer>
    );
};
