import {request} from './request';
import {AuthData} from '../auth';

export interface LoginCredentials {
    email: string,
    password: string,
}

export class AuthApi {
    /**
     * Tries to login with credentials to server
     */
    static login({email, password}: LoginCredentials): Promise<AuthData | any> {
        return request('/auth/cms-login')
            .post({email, password})
            .json()
            .execute();
    }

    /**
     * Refreshes authentication tokens
     */
    static refresh({refreshToken}: AuthData): Promise<AuthData> {
        return request('/auth/refresh')
            .post({refreshToken})
            .json()
            .execute() as Promise<AuthData>;
    }
}
