import { Col, Row } from 'react-bootstrap';
import { BackButton } from '../../../ui/actions/back';
import React from 'react';

type FormContainerProps = {
    title: string,
}

export const FormContainer: React.FC<FormContainerProps> = ({title, children}) => {

    return (
        <>
            <Row>
                <Col className="pt-3 d-flex align-items-center">

                    <BackButton/>

                    <h3 className="m-0">
                        {title}
                    </h3>

                    <div className="flex-grow-1"/>
                </Col>
            </Row>
            <Row className="d-block">
                <Col className="pt-3 align-items-center">
                    {children}
                </Col>
            </Row>
        </>
    );

}
