import React from 'react';
import {Link} from 'react-router-dom';

import 'assets/scss/errors/404.scss';
import logoImage from 'assets/images/logo_180x180.png';

export const NotFound = () => (
    <div className="not-found">
        <div className="info">
            <img src={logoImage} alt="logo"/>
            <h1>404: Not found</h1>
            <Link to="/">
                <h3>Go back to home page.</h3>
            </Link>
        </div>
    </div>
);
