import React from 'react';
import { Button, Col, Row, Tab, Tabs, Form } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { useSelector } from '../../../hooks';
import { LoginSelector } from '../../login/store';
import { useHistory } from 'react-router-dom';
import { Game } from '../../../service/api/game';
import { createTextAreaInput } from '../../ui/input/text-area';
import { Answer, ContentType, ContentTypes, ObjectPlacementType, ObjectPlacementTypes } from '../../../service/api/content';
import { Stage, useStageApi } from '../../../service/api/stage';
import { createAnswersInput } from '../../ui/input/answers';
import { createMarkerInput } from '../ui/input/marker';
import { createObjectInput } from '../ui/input/object';
import { ResourceApi } from '../../../service/api/resource';
import { AuthData } from '../../../service/auth';
import { Transforms } from '../../../service/api/types';
import { FormContainer } from '../ui/form/container';
import { createTextInput } from 'components/ui/input/text';
import { createSelectInput } from '../../ui/input/select';

export type ContentFormData = {
    type: ContentTypes,
    transforms: Transforms,
    imageMarkerId: number | null,
    title: string,
    hintText: string,
    recordingKey: string,
    order: number,
    completeButtonLabel: string,
    autoContinueTimeout: number | null,
    objectPlacement: ObjectPlacementTypes | null;

    image?: number | null,
    video?: number | null,
    model?: number | null,
    objectId?: number | null,
    question?: {
        question: string,
        answers: Answer[],
    },
};

type ContentFormProps = {
    id?: number,
    initialValues: ContentFormData,
};

const TextInput = createTextInput<ContentFormData>();
const TextAreaInput = createTextAreaInput<ContentFormData>();
const AnswerInput = createAnswersInput<ContentFormData>();
const MarkerInput = createMarkerInput<ContentFormData>();
const ObjectPlacementSelectInput = createSelectInput<ContentFormData>(Object.values(ObjectPlacementType).map(
    (value) => ({
        value: value,
        text: value,
    })
));

const ImageInput = createObjectInput<ContentFormData>('Image');
const VideoInput = createObjectInput<ContentFormData>('Video');
const ModelInput = createObjectInput<ContentFormData>('Model');

export const ContentForm: React.FC<ContentFormProps> = ({initialValues, id}) => {
    const auth = useSelector(LoginSelector.auth);
    const history = useHistory<{ game: Game, stage: Stage }>();

    const ContentApi = useStageApi(history.location.state.game).contentApi(history.location.state.stage);

    const getMarkersPage = async (params: { page: number }, auth: AuthData) =>
        (await ResourceApi.getAllByGroup('Marker', params, auth))

    // const getImagesPage = async (params: { page: number }, auth: AuthData) =>
    //     (await ResourceApi.getAllByGroup('Image', params, auth))

    // const getVideosPage = async (params: { page: number }, auth: AuthData) =>
    //     (await ResourceApi.getAllByGroup('Video', params, auth))

    // const getModelPage = async (params: { page: number }, auth: AuthData) =>
    //     (await ResourceApi.getAllByGroup('Model', params, auth))

    const onSubmit = async (values: ContentFormData, {
        //setSubmitting,
        setFieldError,
    }: FormikHelpers<ContentFormData>) => {
        const {
            type,
            transforms,
            imageMarkerId,
            title,
            hintText,
            recordingKey,
            order,
            completeButtonLabel,
            autoContinueTimeout,
            objectPlacement,

            image,
            video,
            question,
            model,
        } = values;

        console.log(autoContinueTimeout);

        let result: any;
        const autoContinueTimeoutParsed = typeof autoContinueTimeout === "string" ? parseInt(autoContinueTimeout) : autoContinueTimeout;

        if (!!id) {
            if (type === 'Image') {
                result = await ContentApi.update({
                    id: id, data: {
                        type: 'Image',
                        objectId: image ?? 0,
                        transforms,
                        imageMarkerId,
                        title,
                        hintText,
                        recordingKey,
                        order,
                        completeButtonLabel,
                        autoContinueTimeout: autoContinueTimeoutParsed,
                        objectPlacement,
                    },
                }, auth);
            } else if (type === 'Video') {
                result = await ContentApi.update({
                    id: id, data: {
                        type: 'Video',
                        objectId: video ?? 0,
                        transforms,
                        imageMarkerId,
                        title,
                        hintText,
                        recordingKey,
                        order,
                        completeButtonLabel,
                        autoContinueTimeout: autoContinueTimeoutParsed,
                        objectPlacement,
                    },
                }, auth);
            } else if (type === 'Model') {
                result = await ContentApi.update({
                    id: id, data: {
                        type: 'Model',
                        objectId: model ?? 0,
                        transforms,
                        imageMarkerId,
                        title,
                        hintText,
                        recordingKey,
                        order,
                        completeButtonLabel,
                        autoContinueTimeout: autoContinueTimeoutParsed,
                        objectPlacement,
                    },
                }, auth);
            } else if (type === 'Question') {
                result = await ContentApi.update({
                    id: id, data: {
                        type: 'Question',
                        title,
                        hintText,
                        recordingKey,
                        order,
                        completeButtonLabel,
                        autoContinueTimeout: autoContinueTimeoutParsed,
                        objectPlacement,
                        transforms: transforms,
                        question: question!.question,
                        answers: question!.answers,
                    },
                }, auth);
            }
        } else {
            if (type === 'Image') {
                result = await ContentApi.create({
                    type: 'Image',
                    title,
                    hintText,
                    recordingKey,
                    order,
                    completeButtonLabel,
                    autoContinueTimeout: autoContinueTimeoutParsed,
                    objectPlacement,
                    objectId: image ?? 0,
                    transforms,
                    imageMarkerId,
                }, auth);
            } else if (type === 'Video') {
                result = await ContentApi.create({
                    type: 'Video',
                    title,
                    hintText,
                    recordingKey,
                    order,
                    completeButtonLabel,
                    autoContinueTimeout: autoContinueTimeoutParsed,
                    objectPlacement,
                    objectId: video ?? 0,
                    transforms,
                    imageMarkerId,
                }, auth);
            } else if (type === 'Model') {
                result = await ContentApi.create({
                    type: 'Model',
                    title,
                    hintText,
                    recordingKey,
                    order,
                    completeButtonLabel,
                    autoContinueTimeout: autoContinueTimeoutParsed,
                    objectPlacement,
                    objectId: model ?? 0,
                    transforms,
                    imageMarkerId,
                }, auth);
            } else if (type === 'Question') {
                result = await ContentApi.create({
                    type: 'Question',
                    title,
                    hintText,
                    recordingKey,
                    order,
                    completeButtonLabel,
                    autoContinueTimeout: autoContinueTimeoutParsed,
                    objectPlacement,
                    transforms: transforms,
                    question: question!.question,
                    answers: question!.answers,
                }, auth);
            }
        }

        if (result.error) {
            if (result.info)
                result.info.forEach((error: any) => setFieldError(error.context.label, error.message));
            else if (result.message)
                alert(result.message);
        } else {
            history.goBack();
        }
    };

    return (
        <FormContainer title="Contents">
            <Formik initialValues={initialValues}
                    onSubmit={onSubmit}
            >
                {({errors, isSubmitting, values, handleChange, setFieldValue}) => (
                    <FormikForm className="py-3">
                        <Row className="justify-content-center">
                            <Col md={12}>
                                <Form.Group>
                                    <TextInput controlId="title"
                                        label="Title"
                                        name="title"
                                        values={values}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group >
                                    <TextInput controlId="hintText"
                                        label="Hint text"
                                        name="hintText"
                                        values={values}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group >
                                    <TextInput controlId="recordingKey"
                                        label="Názov zvukovej stopy"
                                        name="recordingKey"
                                        values={values}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group style={{ marginBottom: "60px" }}>
                                    <TextInput controlId="order"
                                        label="Order"
                                        name="order"
                                        values={values}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <TextInput controlId="completeButtonLabel"
                                        label="Complete button label"
                                        name="completeButtonLabel"
                                        values={values}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <TextInput controlId="autoContinueTimeout"
                                        label="Automatic continuation timeout"
                                        name="autoContinueTimeout"
                                        values={values}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group style={{ marginBottom: "60px" }}>
                                    <ObjectPlacementSelectInput     
                                        controlId="objectPlacement"
                                        label="Umiestnenie objektu"
                                        name="objectPlacement"
                                        values={values}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={9} xs={12} className="pb-3">
                                <Form.Group>
                                    <Form.Label>
                                        Content
                                    </Form.Label>
                                </Form.Group>
                                <Tabs id="type"
                                    variant="tabs"
                                    className="w-100"
                                    activeKey={values.type}
                                    onSelect={key => setFieldValue('type', key)}>
                                    <Tab title={ContentType.Image}
                                        eventKey={ContentType.Image}
                                        className="py-3">
                                        <ImageInput label='Image'
                                                    name='image'
                                                    values={values}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue}
                                        />
                                    </Tab>
                                    <Tab title={ContentType.Video}
                                        eventKey={ContentType.Video}
                                        className="py-3"
                                    >
                                        <VideoInput label='Video'
                                                    name='video'
                                                    values={values}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue}
                                        />
                                    </Tab>
                                    <Tab title={ContentType.Model}
                                        eventKey={ContentType.Model}
                                        className="py-3"
                                    >
                                        <ModelInput label='Model'
                                                    name='model'
                                                    values={values}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue}
                                        />
                                    </Tab>
                                    <Tab title={ContentType.Question}
                                        eventKey={ContentType.Question}
                                        className="py-3"
                                    >
                                        <TextAreaInput controlId="question"
                                                    label="Question"
                                                    name="question.question"
                                                    values={values}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    rows={4}
                                        />

                                        <AnswerInput controlId="answers"
                                                    label="Answers"
                                                    name="question.answers"
                                                    values={values}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue}
                                        />
                                    </Tab>
                                </Tabs>
                            </Col>
                            <Col md={3} xs={12} className="pb-3">
                                <MarkerInput label="Marker"
                                    name="imageMarkerId"
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    onGetPage={getMarkersPage}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-5 mt-lg-0">
                            <Col md={12}
                                 className="d-flex justify-content-end"
                            >
                                <Button
                                    className="w-50 mr-3"
                                    variant="outline-secondary"
                                    type="reset"
                                    disabled={isSubmitting}
                                >
                                    Clear
                                </Button>
                                <Button
                                    className="w-50"
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </FormikForm>
                )}
            </Formik>
        </FormContainer>
    );
};
