import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Indexable } from './hooks';
import { createGenericList, GenericListContainer, ListProps } from './generic';

const createListContainer = <T extends Indexable>(): GenericListContainer<T> => {
    return props => {
        const {
            // count,
            pages,

            page,
            data,
            // isLoading,

            getNextPage,
            getPrevPage,

            onEdit,
            onDelete,
        } = props;

        const ListItem = props.listItem;

        return (
            <>
                <Row>
                    {data.map((item: T, key) => (
                        <ListItem item={item}
                                  key={key}
                                  onEdit={onEdit(item.id)}
                                  onDelete={onDelete(item.id)}
                        />
                    ))}
                </Row>
                {page !== -1 && (
                    <Row className="pb-3">
                        <Col sm={0} md={0}
                             className="d-lg-none"
                        />
                        <Col sm={12} md={12} lg={'auto'} className={`${pages <= 1 ? "d-none" : ""}`}>
                            <nav aria-label="pagination">
                                <ul className="pagination">
                                    <li className={`page-item ${page <= 1 ? "disabled" : "" }`}>
                                        <Button className="page-link" onClick={getPrevPage}>Previous</Button>
                                    </li>
                                    <li className={`page-item ${page >= pages ? "disabled" : "" }`}>
                                        <Button className="page-link" onClick={getNextPage}>Next</Button>
                                    </li>
                                </ul>
                            </nav>
                        </Col>
                    </Row>
                )}
            </>
        );
    };
};

export const createList = <T extends Indexable>(): React.FC<ListProps<T>> =>
    createGenericList<T>(
        createListContainer<T>(),
    );
