import React from 'react';
import {createTableList} from '../ui/list';
import {GameItem} from './item';
import {Game, GameApi} from '../../../service/api/game';

const List = createTableList<Game>();

export const GameList: React.FC = () => (
    <List title="Games"
          itemName="Game"
          columns={[
              '#',
              'Name',
              'Difficulty',
              'Is public',
              'Location',
              'Actions',
          ]}
          createUrl="/games/create"
          editUrl="/games/edit"
          onGetPage={async (params, auth) =>
              await GameApi.getAll(params, auth)
          }
          onConfirmDelete={GameApi.delete}
          listItem={GameItem}
    />
);
