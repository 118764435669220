import {request} from './request';
import {AuthData} from '../auth';
import {PagingParams} from './paging';
import {Coords, GetAllData, UpdateData} from './types';

export const Difficulty = {
    Easy: 1,
    Medium: 2,
    Hard: 3,
} as const;

export type DifficultyEnum = 1 | 2 | 3;

export const DifficultyLabel = {
    [Difficulty.Easy]: 'Easy',
    [Difficulty.Medium]: 'Medium',
    [Difficulty.Hard]: 'Hard',
} as const;

export type Game = {
    id: number,
    name: string,
    subtitle: string,
    description: string,
    difficulty: DifficultyEnum,
    location: Coords,
    createdAt: string,
    updatedAt: string,
    isPublic: boolean,
};

export type CreateGameData = {
    name: string,
    subtitle: string,
    description: string,
    difficulty: number,
    location: Coords,
};

export type UpdateGameData = UpdateData<CreateGameData>;
export type GetAllGameData = GetAllData<Game>;

export type GetGameData = {
    id: number,
};

export class GameApi {
    static create(data: CreateGameData, auth: AuthData) {
        return request('/game')
            .post(data)
            .json()
            .auth(auth)
            .execute();
    }

    static getAll(params: PagingParams, auth: AuthData) {
        return request(`/game/all`)
            .get()
            .json()
            .params(params)
            .auth(auth)
            .execute() as Promise<GetAllGameData>;
    }

    static update({id, data}: UpdateGameData, auth: AuthData) {
        return request(`/game/${id}`)
            .put(data)
            .json()
            .auth(auth)
            .execute();
    }

    static delete({id}: GetGameData, auth: AuthData) {
        return request(`/game/${id}`)
            .delete()
            .auth(auth)
            .execute();
    }
}
