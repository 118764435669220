import React from 'react';
import {MarkerForm} from './form';

export const MarkerCreate: React.FC = () => {
    return (
        <MarkerForm initialValues={{
            files: null,
            group: 'Marker',
            name: '',
        }}/>
    );
};
