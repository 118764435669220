import {ObjectList} from './list';
import {ObjectCreate} from "./create";

export const ObjectsRoutes = [
    {
        pathname: '/objects',
        exact: true,
        component: ObjectList,
        title: 'Objects',
        menuEntry: true,
    },
    {
        pathname: '/objects/create',
        exact: true,
        component: ObjectCreate,
        title: 'Objects',
        menuEntry: false,
    },
];
