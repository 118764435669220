import React, {useMemo} from 'react';
import {Form} from 'react-bootstrap';
import {FormikErrors, FormikValues} from 'formik';

type SelectInputProps<T extends FormikValues> = {
    controlId?: string,
    label: string,
    name: keyof T & string,
    values: T,
    errors: FormikErrors<T>,
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ?
            void :
            (e: string | React.ChangeEvent<any>) => void;
    },
};

export type Option = { value: number | string,  text: string};
export type Options = Option[];

export const createSelectInput: <T extends FormikValues>(options: Options) => React.FC<SelectInputProps<T>> = (options) => {
    const items = options.map(({value, text}, key) => (
        <option value={value} key={key}>
            {text}
        </option>
    ));

    return ({controlId, label, name, values, errors, handleChange}) => (
        <Form.Group controlId={controlId}>
            <Form.Label>
                {label}
            </Form.Label>
            <Form.Control as="select"
                          name={name}
                          value={values[name]}
                          onChange={handleChange}
                          isInvalid={!!errors[name]}
            >
                {items}
            </Form.Control>
            {errors[name] && (
                <Form.Text className="invalid-feedback">
                    {errors[name]}
                </Form.Text>
            )}
        </Form.Group>
    );
};

export const createSelectEnumInput = <T extends FormikValues>(options: string[]) => createSelectInput<T>(
    options.map((option, id) => ({
        value: id + 1,
        text: option,
    })),
);

export const useSelectInput = <T extends FormikValues>(options: Options) => useMemo(
    () => createSelectInput<T>(options),
    [options]
);
