import {configureStore} from '@reduxjs/toolkit';

import {loginReducer} from './components/login/store';
import {persistStore} from 'redux-persist';

export const AppStore = configureStore({
    reducer: {
        login: loginReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        thunk: true,
        immutableCheck: true,
        serializableCheck: false,
    }),
});

export const AppPersistor = persistStore(AppStore);

export type AppState = ReturnType<typeof AppStore.getState>;
export type AppDispatch = typeof AppStore.dispatch;
