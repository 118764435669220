import {ContentsList} from './list';
import {ContentCreate} from './create';
import {ContentEdit} from './edit';

export const ContentsRoutes = [
    {
        pathname: '/contents',
        exact: true,
        component: ContentsList,
        title: 'Contents',
        menuEntry: false,
    },
    {
        pathname: '/contents/create',
        exact: true,
        component: ContentCreate,
        title: 'Contents',
        menuEntry: false,
    },
    {
        pathname: '/contents/edit',
        exact: true,
        component: ContentEdit,
        title: 'Contents',
        menuEntry: false,
    },
];
